import PurchaseCredits from "./Credits/PurchaseCredits";
import { PaymentHistory } from "./Credits/PaymentHistory";
import UseTranslation from "../../../hooks/useTranslation";
import { PageSettingsI18n } from "./PageSettings.i18n";
import UseUserCredits from "../../../hooks/useUserCredits";
import { UserSubscription } from "../../Cards/UserSubscription";
import { IconLoader2 } from "@tabler/icons-react";

const PlanSettings = () => {
  const { t } = UseTranslation(PageSettingsI18n);

  const {
    credits,
    isLoadingCredits,
    hasSubscriptionActive,
    isLoadingSubscription,
  } = UseUserCredits();
  const isActiveSubscription = hasSubscriptionActive();

  return (
    <>
      {!isLoadingSubscription ? (
        <>
          {isActiveSubscription ? (
            <UserSubscription large showCreditsExplanation />
          ) : (
            <>
              <div className="d-flex flex-column gap-3">
                <h4>{t("credits.title")}</h4>
                <PurchaseCredits
                  credits={{
                    amount: credits?.amount || 0,
                    isLoading: isLoadingCredits,
                  }}
                />
              </div>
              <PaymentHistory />
            </>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <IconLoader2 size={28} className="spin" />
        </div>
      )}
    </>
  );
};

export default PlanSettings;
