import iconAizzy from "../../assets/images/icons-new-layout/icon.svg";
import COLORS from "../../utils/constants/colors";
import { PAYMENT_METHODS } from "../../utils/constants/paymentMethods";
import ButtonType1 from "../Buttons/ButtonType1";
import lock from "../../assets/images/icons-new-layout/lockSimple.svg";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import { ModalCreditsI18n } from "../Modals/ModalCredits.i18n";
import UseTranslation from "../../hooks/useTranslation";
import { formatNumberMillion } from "../../utils/helpers";

const CardOrderSummary = ({
  inputValue,
  selectedPayment,
  aizAmount,
  t2,
  handleClick,
  isLoading,
}) => {
  const state = useSelector(state => state);
  const { t } = UseTranslation(ModalCreditsI18n);

  const convertNameMethod = () => {
    if (selectedPayment === PAYMENT_METHODS.AIZ) {
      return "Metamask";
    }
    if (selectedPayment === PAYMENT_METHODS.PIX) {
      return "Pix";
    }
    if (selectedPayment === PAYMENT_METHODS.CARD) {
      return "Stripe";
    }
  };

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;

  return (
    <div
      className="d-flex flex-column gap-5"
      style={{
        padding: isMobile ? "16px" : "38px",
        width: "100%",
        maxWidth: 560,
        background: COLORS.white,
        borderRadius: 16,
      }}
    >
      <div className="d-flex align-items-center gap-3">
        <img src={iconAizzy} alt="" width={40} height={40} />
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div>
            <h5 className="m-0 font-size-18" style={{ color: COLORS.gray700 }}>
              {t("chatbotCredits")}
            </h5>
            {selectedPayment === PAYMENT_METHODS.AIZ && inputValue > 0 && (
              <div className="d-flex flex-column gap-2">
                <span style={{ color: COLORS.gray400 }}>
                  {aizAmount.toFixed(4)} AIZ
                </span>
              </div>
            )}
          </div>
          <span style={{ color: COLORS.gray400 }}>
            $
            {isNaN(inputValue) || inputValue === ""
              ? 0
              : formatNumberMillion(inputValue)}
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="m-0 font-size-18">Total</h5>
          <div>
            $
            {isNaN(inputValue) || inputValue === ""
              ? 0
              : formatNumberMillion(inputValue)}
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <ButtonType1
            text={`${t2("modals.credits.button.pay")} ${selectedPayment}`}
            props={{
              width: 16,
              height: 16,
              disabled: isLoading,
              style: {
                width: "100%",
                padding: "12px 0",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              onClick: handleClick,
            }}
            isLoading={isLoading}
            icon={lock}
          />
          <p className="m-0 font-size-14 text-center">
            {t2("modals.credits.textOrderSummary", {
              method: convertNameMethod(),
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardOrderSummary;
