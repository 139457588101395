export const ModalNoCreditsI18n = {
  "pt-BR": {
    title: "Adicione créditos para iniciar uma conversa",
    description:
      "Desbloqueie as possibilidades poderosas de AI, gerar imagens, criar textos únicos e explorar as possibilidades infinitas",
    label: "DESABILITE AS FUNÇOES",
    list: {
      item1: "Criar conversas ilimitadas",
      item2: "Acessar geração de imagens e textos",
      item3: "Desbloquear 175+ modelos de linguagem",
      item4: "Treinar IA para executar comandos e tarefas complexas",
    },
    value: "Valor",
    estimated: "Estimativa de pergunta usando GPT-3.5 Turbo:",
    button: "Talvez mais tarde",
  },
  "en-US": {
    title: "Add credits to your account firstto start a conversation",
    description:
      "Unlock powerful AI models—generate images, craft unique texts,and explore endless possibilities",
    label: "UNLOCK FEATURES",
    list: {
      item1: "Unlimited chat creations",
      item2: "Access image and text generation",
      item3: "Unlock 175+ language models",
      item4: "Train AI to execute commands and complex tasks",
    },
    value: "Value",
    estimated: "Question Estimation using GPT-3.5 Turbo:",
    button: "Maybe later",
  },
  "es-ES": {
    title: "Agrega créditos para iniciar una conversa",
    description:
      "Desbloquea las posibilidades poderosas de AI, generar imágenes y crear textos únicos y explorar las posibilidades infinitas",
    label: "DESACTIVA FUNCIONES",
    list: {
      item1: "Crea conversaciones ilimitadas",
      item2: "Accede a la generación de imágenes y textos",
      item3: "Desbloquea 175+ modelos de lenguaje",
      item4: "Entrena la IA para ejecutar comandos y tareas complejas",
    },
    value: "Valor",
    estimated: "Estimativa de pregunta usando GPT-3.5 Turbo:",
    button: "Tal vez más tarde",
  },
};
