/* eslint-disable react-hooks/exhaustive-deps */
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { gerReferral, updateReferral } from "../../api";
import cookie from "../../utils/cookie";

const ReferralChecker = ({ children }) => {
  const user = useUser();
  const supabaseClient = useSupabaseClient();
  const [referral, setreferral] = useState(cookie.get("referral"));
  const urlParams = new URLSearchParams(window.location.search);
  const referralParams = urlParams.get("referral");

  useEffect(() => {
    if (referralParams) {
      cookie.set("referral", referralParams);
      setreferral(referralParams);
    }
  }, [referralParams]);

  useEffect(() => {
    if (referral && !user?.user_metadata?.referral) {
      gerReferral(referral)
        .then(async (res: any) => {
          if (res) {
            await Promise.all([
              supabaseClient.auth.updateUser({
                data: { referral: res },
              }),
              updateReferral(res?.id),
            ]);
            cookie.remove("referral");
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [user, referral]);
  return children;
};

export default ReferralChecker;
