/* eslint-disable no-restricted-globals */
import { IconHeart } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const CardAssistantLandingPage = ({
  image,
  title,
  description,
  icon,
  likes,
  name,
}) => {
  const state = useSelector(state => state);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <>
      <div className={`card-assistants-landing-page`}>
        <img src={image} alt={title} />
        <div className="details">
          <h2 className="m-0">{title}</h2>
          <p className="m-0">{description}</p>
          <div className="d-flex align-items-center justify-content-between gap-3">
            <div className="d-flex align-items-center gap-1">
              <img src={icon} alt="" width={16} height={16} style={{ borderRadius: breakpointLg ? 3 : 6 }}/>
              <span className="one-line">{name}</span>
            </div>
            <div>
              <IconHeart
                style={{
                  minWidth: breakpointLg ? 10 : 16,
                  minHeight: breakpointLg ? 10 : 16,
                  maxWidth: breakpointLg ? 10 : 16,
                  maxHeight: breakpointLg ? 10 : 16,
                }}
                stroke={1.5}
              />
              <span style={{ width: breakpointLg ? 7.5 : 14 }}>{likes}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardAssistantLandingPage;
