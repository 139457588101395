const InputCreateAssistant = ({
  label,
  value,
  isReadOnly = true,
  disabled = true,
}) => {
  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <span className="span">{label}</span>
      <div className="div-input-general-info">
        <input
          type="text"
          value={value}
          readOnly={isReadOnly}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InputCreateAssistant;
