import { IconChevronDown, IconChevronLeftPipe } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";

const ButtonOpenLeftBar = ({ onClick, state, iconLeftPipe, handleSidebarToggle, ref, dropDown, minHeight }) => {
  return (
    <button
      className={"btn-open-bar"}
      style={{
        alignSelf: state?.configsGeneralReducer?.openLeftBar
          ? "flex-end"
          : "center",
        padding: state?.configsGeneralReducer?.openLeftBar && iconLeftPipe ? "0 12px" : "0",
        minHeight: minHeight
      }}
      onClick={
        !state?.configsGeneralReducer?.openLeftBar ? handleSidebarToggle : null
      }
    >
      <div
      ref={ref}
        className="icon-open-bar d-flex justify-content-center align-items-center"
        style={{
          borderRadius: 4,
          minWidth: 24,
          height: 24,
          background: dropDown ? COLORS.gray100 : "",
        }}
        onClick={onClick}
      >
        {iconLeftPipe ? (
          <IconChevronLeftPipe
            style={{
              width: 16,
              height: 16,
              transition: "all .2s",
              transform: `rotate(${
                state?.configsGeneralReducer?.openLeftBar ? 0 : 180
              }deg)`,
            }}
          />
        ) : (
          <IconChevronDown
            style={{
              color: dropDown ? COLORS.gray700 : COLORS.gray500,
              width: 16,
              height: 16,
              transition: "all .2s",
              transform: `rotate(${
                dropDown ? 180 : 0
              }deg)`,
            }}
            stroke={1.5}
          />
        )}
      </div>
    </button>
  );
};

export default ButtonOpenLeftBar;
