import { useSelector } from "react-redux";
import FooterLandingPage from "../../components/PagesComponents/LandingPage/FooterLandingPage";
import HeaderLandingPage from "../../components/PagesComponents/LandingPage/HeaderLandingPage";
import SectionFiveLandingPage from "../../components/PagesComponents/LandingPage/SectionFiveLandingPage";
import SectionForLandingPage from "../../components/PagesComponents/LandingPage/SectionForLandingPage";
import SectionOneLandingPage from "../../components/PagesComponents/LandingPage/SectionOneLandingPage";
import SectionThreeLandingPage from "../../components/PagesComponents/LandingPage/SectionThreeLandingPage";
import SectionTwoLandingPage from "../../components/PagesComponents/LandingPage/SectionTwoLandingPage";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import SectionFaqs from "../../components/PagesComponents/LandingPage/SectionFaqs";

const LandingPage = () => {
  const state = useSelector(state => state);

  const lg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;
  return (
    <>
      <HeaderLandingPage />
      <section
        className="w-100 d-flex justify-content-center mb-4 main-landing-page"
        style={{ paddingTop: 130, height: "100vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <div
          className="container-landing "
          style={{ width: `${lg ? "calc(100% - 160px)" : "100%"}}`}}
        >
          <SectionOneLandingPage />
          <SectionTwoLandingPage />
          <SectionThreeLandingPage />
          <SectionForLandingPage />
          <SectionFaqs />
          <SectionFiveLandingPage />
          <FooterLandingPage />
        </div>
      </section>
    </>
  );
};

export default LandingPage;
