import { IconChevronDown, IconLoader2, IconPower } from "@tabler/icons-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/smallHooks";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";
import { formatPriceAiz } from "../../utils/helpers";
import UseTranslation from "../../hooks/useTranslation";
import { PageChatI18n } from "../Add-ons/PageChat.i18n";
import UseUserCredits from "../../hooks/useUserCredits";
import { UserSubscription } from "../Cards/UserSubscription";

const MenuNewLayout = ({ toggle, dropdownOpen, user }) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const { t } = UseTranslation(PageChatI18n);

  const avatarProfile = user?.user_metadata?.picture;

  const { credits, hasSubscriptionActive } = UseUserCredits();
  const isActiveSubscription = hasSubscriptionActive();

  return (
    <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav className="box-account">
        <div className="d-flex align-items-center gap-2">
          {avatarProfile ? (
            <img
              src={avatarProfile}
              alt=""
              className="profile-user"
              style={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img src={imgProfile} alt="" />
          )}
          <span className={"one-line name-user"}>
            {user && user?.user_metadata?.name
              ? user?.user_metadata?.name
              : user?.email?.split("@")[0]}
          </span>
        </div>
        <IconChevronDown
          stroke={1}
          size={14}
          style={{
            transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
            minWidth: 14,
            minHeight: 14,
          }}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-account mt-1 p-4 p-sm-3">
        <div className="d-flex justify-content-between align-items-center">
          <h4>{t("account")}</h4>
          <IconPower
            onClick={() => {
              logout();
              toggle(!dropdownOpen);
            }}
            stroke={1.5}
            style={{
              color: "red",
              marginLeft: 8,
              cursor: "pointer",
              width: 20,
              height: 20,
            }}
          />
        </div>
        <div className="d-flex flex-column gap-3 mt-sm-3 mt-4">
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => navigate("/settings?tab=credits")}
          >
            {isActiveSubscription ? (
              <UserSubscription />
            ) : (
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                }}
              >
                <strong className="m-0 p-0">{t("credits")}</strong>
                <div className="d-flex align-items-end">
                  {credits ? (
                    <h3 className="my-0" style={{ marginRight: 12 }}>
                      ${formatPriceAiz(Number(credits?.amount || 0))}
                    </h3>
                  ) : (
                    <IconLoader2 size={16} className="spin" />
                  )}
                </div>
              </div>
            )}
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <div>
              <i className={"bx bx-cog"} />
              {t("settings")}
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => {
              navigate("/assistants");
            }}
          >
            <div>
              <i className={"bx bx-bot"} />
              {t("assistants")}
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuNewLayout;
