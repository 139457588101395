import { IconCheck } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import UseTranslation from "../../../hooks/useTranslation";
import { ModalSelectModelI18n } from "../ModalSelectModel.i18n";
import { useState } from "react";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import ButtonType1 from "../../Buttons/ButtonType1";
import imagesModels from "../../../utils/constants/imagesModel";

export const ListModelFlat = ({
  machines,
  activeModel,
  setActiveModel,
  searchModel,
}) => {
  const [showButton, setShowButton] = useState(null);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const state = useSelector(state => state);
  const { t } = UseTranslation(ModalSelectModelI18n);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.MD;

  const filteredMachines = searchModel
    ? machines?.filter(
        machine =>
          machine.name?.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.id.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.description.toLowerCase().includes(searchModel.toLowerCase())
      )
    : machines;

  return (
    <>
      {!openModalDetails ? (
        <ul className="animate__animated animate__fadeIn animate__faster d-flex flex-column gap-3">
          {filteredMachines &&
            filteredMachines.map((machine, idx) => {
              const modelImageObj = imagesModels.find(
                model => model.name === machine?.architecture?.tokenizer
              );
              const modelImage = modelImageObj?.image;
              return (
                <li
                  className={`${
                    activeModel === machine.id ? "active" : ""
                  } d-flex align-items-center justify-content-between`}
                  key={machine.id}
                  onClick={() => {
                    setActiveModel(machine.id);
                  }}
                  onMouseEnter={() => setShowButton(idx)}
                  onMouseLeave={() => setShowButton(null)}
                >
                  <div className="d-flex align-items-center" style={{ gap: 12 }}>
                    <img src={modelImage} alt="" width={32} height={32} style={{ borderRadius: "8px" }}/>
                    <div className="d-flex flex-column align-items-start">
                      <p
                        className="m-0 font-size-12 one-line"
                        style={{ color: COLORS.gray900 }}
                      >
                        {machine.name}
                      </p>
                      <div className="d-flex gap-3 align-items-center">
                        {!isMobile && (
                          <p
                            type="button"
                            className="m-0 d-flex align-items-center font-size-11"
                            style={{color: COLORS.gray500}}
                          >
                            $
                            {(machine.pricing.completion * 1000000)
                              .toString()
                              .slice(0, 5)}{" "}
                            {t("list.tokeWeb")}
                          </p>
                        )}
                      </div>
                    </div>
                    {showButton === idx && (
                      <button
                        style={{
                          background: COLORS.white,
                          padding: "3px 10px",
                          borderRadius: 6,
                          marginLeft: 12,
                        }}
                        type="button"
                        className="d-flex align-items-center font-size-11"
                        onClick={e => {
                          e.stopPropagation();
                          setOpenModalDetails(true);
                          setSelectedMachine(machine);
                        }}
                      >
                        {t("details.buttonDetails")}
                      </button>
                    )}
                  </div>

                  {activeModel === machine.id && (
                    <IconCheck stroke={1.5} width={16} height={16} />
                  )}
                </li>
              );
            })}
        </ul>
      ) : (
        <div className="animate__animated animate__fadeIn details-model d-flex flex-column gap-3">
          <h4 className="m-0">{t("details.details")}:</h4>
          <ul className="d-flex flex-column gap-2">
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>{t("details.name")}:</span>
              <p>{selectedMachine.name}</p>
            </li>
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>
                {t("details.description", {
                  text: selectedMachine.description,
                })}
                :
              </span>
              <p>{selectedMachine.description}</p>
            </li>
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>{t("details.modality")}:</span>
              <p>{selectedMachine.architecture.modality.split("-")[0]}</p>
            </li>
            {isMobile && (
              <li className="d-flex flex-column justify-content-center align-items-start">
                <span>{t("list.token")}:</span>
                <p>
                  $
                  {(selectedMachine.pricing.completion * 1000000)
                    .toString()
                    .slice(0, 5)}
                </p>
              </li>
            )}
          </ul>
          <div className="d-flex justify-content-center gap-3 mt-3">
            <ButtonType1
              text={t("details.back")}
              props={{
                style: { padding: "10px 20px", borderRadius: 8 },
                type: "button",
                onClick: () => {
                  setOpenModalDetails(false);
                },
              }}
            />
            <ButtonType1
              text={t("details.selectModel")}
              props={{
                style: { padding: "10px 20px", borderRadius: 8 },
                type: "button",
                onClick: () => {
                  setOpenModalDetails(false);
                  setActiveModel(selectedMachine.id);
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
