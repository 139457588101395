import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { ModalInstallMetamaskI18n } from "./ModalInstallMetamask.i18n";
import UseTranslation from "../../hooks/useTranslation";

const ModalInstallMetamask = ({
  modalInstallMetamask,
  onclick,
  iconMetaMask,
}) => {
  const { t } = UseTranslation(ModalInstallMetamaskI18n);

  return (
    <Modal isOpen={modalInstallMetamask} backdrop centered>
      <ModalBody className="p-4 mb-1">
        <p className="text-center">{t("description.label")}</p>
        <div className="d-flex justify-content-center w-100 mt-4">
          <Link
            className="btn btn-light d-flex"
            to="https://metamask.io/download.html"
            target="_blank"
          >
            <img src={iconMetaMask} alt="" />
            <p style={{ marginLeft: 8 }}>{t("button.download")}</p>
          </Link>
          <button
            className="btn btn-light"
            style={{ marginLeft: 25 }}
            onClick={onclick}
          >
            {t("button.close")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalInstallMetamask;
