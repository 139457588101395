/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IconFileText,
  IconBrowser,
  IconBrandYoutube,
  IconChevronRight,
} from "@tabler/icons-react";
import COLORS from "../../../../utils/constants/colors";

const CardAttachment = ({ attachment, onClick }) => {
  const iconColor = attachment?.processed ? "green" : "orange";

  const iconMap = {
    document: <IconFileText size={20} color={iconColor} stroke={2} />,
    website: <IconBrowser size={20} color={iconColor} stroke={2} />,
    video: <IconBrandYoutube size={20} color={iconColor} stroke={2} />,
  };

  const icon = iconMap[attachment?.type] || (
    <IconFileText size={20} color={iconColor} stroke={2} />
  );

  return (
    <>
      <div
        className="d-flex align-items-center gap-2 btn-manage-chat"
        style={{
          background: COLORS.gray100,
          borderRadius: 8,
          padding: 8,
        }}
      >
        <span className="font-size-12">
          {attachment && attachment.length} Documents Attached
        </span>
        |
        <button onClick={onClick}>
          <u className=" font-size-12">Manage</u>
          <IconChevronRight stroke={1.5} width={14} height={14} />
        </button>
      </div>
    </>
  );
};

export default CardAttachment;
