const TextAreaField = ({ label, value }) => {
  return (
    <div className="d-flex w-100 justify-content-between align-items-start">
      <span className="span">{label}</span>
      <div className="textarea-data">
        <textarea value={value} readOnly disabled />
      </div>
    </div>
  );
};

export default TextAreaField;
