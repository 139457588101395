import { IconX } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";

const ButtonCloseModal = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className="d-flex justify-content-center align-items-center"
      style={{ width: 32, height: 32, background: COLORS.gray100, borderRadius: 6 }}
    >
      <IconX stroke={1.5} style={{ width: 16, height: 16 }} />
    </button>
  );
};

export default ButtonCloseModal;
