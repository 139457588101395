import { useEffect } from "react";

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Verifica se ref.current existe ou se o evento ocorreu dentro do elemento referenciado
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      // Chama o handler se o clique foi fora do elemento referenciado
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    // Limpa os event listeners quando o componente é desmontado
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;