import React, { useLayoutEffect } from "react";
import { FaqsI18n } from "./Faqs.i18n";
import useTranslation from "../../../hooks/useTranslation";
import { Collapse } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const Faqs = () => {
  const { t } = useTranslation(FaqsI18n);

  const state = useSelector(state => state);

  const breakpointLg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const faqList = [
    { key: "1", label: t("questionOne"), children: <p>{t("responseOne")}</p> },
    { key: "2", label: t("questionTwo"), children: <p>{t("responseTwo")}</p> },
    { key: "3", label: t("questionThree"), children: <p>{t("responseThree")}</p> },
    { key: "4", label: t("questionFour"), children: <p>{t("responseFour")}</p> },
    { key: "5", label: t("questionFive"), children: <p>{t("responseFive")}</p> },
    { key: "6", label: t("questionSix"), children: <p>{t("responseSix")}</p> },
  ];

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Animação de escada para os itens da FAQ
    const faqPanels = document.querySelectorAll(".faq-panel");

    faqPanels.forEach((panel, index) => {
      gsap.fromTo(
        panel,
        { opacity: 0, x: 500 }, // Começa invisível e abaixo
        {
          opacity: 1,
          x: 0, 
          duration: 1,
          delay: index * 0.2,
          scrollTrigger: {
            trigger: panel,
            start: breakpointLg ? "top 100%" : "top 85%",
            end: breakpointLg ? "bottom 85%" : "bottom 80%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );
    });

    // Cleanup
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <Collapse className="faqs-collapse" accordion>
      {faqList.map(faq => (
        <Panel
          header={faq.label}
          key={faq.key}
          className="faq-panel"
        >
          {faq.children}
        </Panel>
      ))}
    </Collapse>
  );
};

export default Faqs;
