import { useDispatch } from "react-redux";
import ButtonType1 from "../../Buttons/ButtonType1";
import { setOpenLogin } from "../../../redux/general/action";
import {
  useGetConfigsMainChat,
  useGetIsLogged,
} from "../../../hooks/smallHooks";
import { useState } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import MenuNewLayout from "../MenuNewLayout";
import COLORS from "../../../utils/constants/colors";
import { useLocation } from "react-router-dom";
import TitleTopBar from "../../PagesComponents/PageChat/Chats/ToolsTopBar/TitleTopBar";
import UseTranslation from "../../../hooks/useTranslation";
import { PageAssistantsI18n } from "../../PagesComponents/PageAssistants/HeaderPageAssistants/HeaderPageAssistants.i18n";

const Account = ({ publishAssistant, onClick }) => {
  const dispatch = useDispatch();
  const isLogged = useGetIsLogged();
  const user = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = window.matchMedia("(max-width: 991.92px)").matches;
  const namePublish = window.matchMedia("(max-width: 460.92px)").matches;
  const configsMainChat = useGetConfigsMainChat();
  const location = useLocation();

  const { t } = UseTranslation(PageAssistantsI18n);

  return (
    <div className={"account-group"}>
      {isLogged ? (
        <>
          <div className="btn-publish d-flex align-items-center">
            {publishAssistant && (
              <ButtonType1
                text={namePublish ? t("create") : t("publish")}
                props={{
                  style: {
                    backgroundColor: COLORS.gray900,
                    padding: "0 16px",
                    borderRadius: 10,
                    marginRight: 12,
                  },
                  onClick: onClick,
                }}
              />
            )}
            {localStorage.getItem("webModeLogin") === "Web 2" ? (
              <MenuNewLayout
                toggle={() => setDropdownOpen(!dropdownOpen)}
                user={user}
                dropdownOpen={dropdownOpen}
              />
            ) : (
              <MenuNewLayout
                toggle={() => setDropdownOpen(!dropdownOpen)}
                user={user}
                dropdownOpen={dropdownOpen}
              />
            )}
            {isMobile &&
              configsMainChat?.chatData?.channelName &&
              location.pathname !== "/assistants" &&
              location.pathname !== "/settings" &&
              location.pathname !== "/about" &&
              (
                <TitleTopBar configsMainChat={configsMainChat} />
              )}
          </div>
        </>
      ) : (
        <div className="d-flex align-items-center">
          <ButtonType1
            text={t("sigIn")}
            variation="primary"
            props={{
              className: "btn-login d-flex align-items-center",
              onClick: () => dispatch(setOpenLogin(true)),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Account;
