/* eslint-disable no-unused-vars */
import ModalGlobal from "./ModalGlobal";
import imgModalNoCredits from "../../assets/images/icons-new-layout/modal-no-credits.png";
import { IconCircleCheck } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import { formatNumberMillion } from "../../utils/helpers";
import ButtonType1 from "../Buttons/ButtonType1";
import { useNavigate } from "react-router-dom";
import { ModalNoCreditsI18n } from "./ModalNoCredits.i18n";
import UseTranslation from "../../hooks/useTranslation";
import { useState } from "react";
import { showErrorNotification } from "../../services/notifications";

const ModalNoCredits = ({ openModal, setOpenModal }) => {
  const [valueInput, setValueInput] = useState(10);
  const [focus, setFocus] = useState(false);
  const state = useSelector(state => state);
  const navigate = useNavigate();

  const { t } = UseTranslation(ModalNoCreditsI18n);

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;

  const handleBlur = () => {
    if (Number(valueInput) < 10) {
      showErrorNotification("The minimum amount of credits is 10");
      setValueInput(10);
    }
  };

  return (
    <ModalGlobal
      open={openModal}
      classNameChildren={`p-0 modal-no-credits justify-content-between ${
        openModal
          ? "animate__animated animate__fadeInDown animate__faster"
          : "animate__animated animate__fadeOut"
      }`}
      onClick={() => {
        setOpenModal(false);
      }}
    >
      <div className="d-flex">
        {!isMobile && (
          <div>
            <img src={imgModalNoCredits} alt="" loading="lazy" />
          </div>
        )}
        <div className="d-flex flex-column" style={{ gap: 32, padding: 40 }}>
          <div className="d-flex flex-column gap-4">
            <h3 style={{ color: COLORS.gray900, margin: 0 }}>{t("title")}</h3>
            <span style={{ color: COLORS.gray700 }}>{t("description")}</span>
            <ul className="d-flex flex-column gap-3">
              <label style={{ color: COLORS.gray700 }}>{t("label")}</label>
              <li>
                <IconCircleCheck stroke={1.5} size={20} />
                {t("list.item1")}
              </li>
              <li>
                <IconCircleCheck stroke={1.5} size={20} />
                {t("list.item2")}
              </li>
              <li>
                <IconCircleCheck stroke={1.5} size={20} />
                {t("list.item3")}
              </li>
              <li>
                <IconCircleCheck stroke={1.5} size={20} />
                {t("list.item4")}
              </li>
            </ul>
          </div>
          <div className="d-flex flex-column gap-3 mt-2">
            <span style={{ color: COLORS.gray700 }}>{t("value")} (USD)</span>
            <div
              className="w-100 d-flex align-items-center gap-2"
              style={{
                maxWidth: 800,
                height: isMobile ? 54 : 64,
                borderRadius: 12,
                border: `1px solid ${COLORS.gray300}`,
                padding: "0 20px",
              }}
            >
              <span className={`font-size-${isMobile ? 14 : 18}`}>$</span>
              <input
                value={isNaN(valueInput) ? 0 : valueInput}
                onChange={e => setValueInput(e.target.value)}
                style={{
                  border: "none",
                  fontSize: isMobile ? 14 : 18,
                  outline: "none",
                }}
                onFocus={() => setValueInput("")}
                onBlur={() => {
                  setFocus(false);
                  handleBlur();
                }}
                min={10}
                onFocusCapture={() => setFocus(true)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end flex-grow-1 align-items-end gap-3">
            <ButtonType1
              variation="secondary"
              text={t("button")}
              props={{
                onClick: () => setOpenModal(false),
                disabled: false,
                style: {
                  backgroundColor: COLORS.gray100,
                  borderRadius: 12,
                  padding: isMobile ? "12px 0" : "16px 0",
                  width: 140,
                },
              }}
            />
            <ButtonType1
              text={"Continue"}
              props={{
                onClick: () =>
                  navigate(`/settings?tab=credits&pay=${valueInput}`),
                disabled: false,
                style: {
                  borderRadius: 12,
                  padding: isMobile ? "12px 0" : "16px 0",
                  width: 140,
                },
              }}
            />
          </div>
        </div>
      </div>
    </ModalGlobal>
  );
};

export default ModalNoCredits;
