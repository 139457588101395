/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import { useQuery } from "react-query";
import { QUERY_KEYS, queryClient } from "../../../libs/ReactQuery";
import {
  getMyAssistants,
  getAssistantImagesDefalut,
} from "../../../api/assistants";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { updateAssistantData } from "../../../redux/createAssistants/action";
import ModalImagesAvatar from "../../Modals/ModalImagesAvatar";
import ModalEditAssistant from "../../Modals/ModalEditAssistant";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import CardCreateAssistant from "../../Cards/CardCreateAssistant";
import { showRealoadNotification } from "../../../services/notifications";

const ListMyAssistants = ({ onClick }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [modalEditImage, setModalEditImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [savedData, setSavedData] = useState({});

  const handleSelectGeneratedImage = url => {
    setAvatar(url);
  };
  const handleEditRole = role => {
    setRole(role);
  };

  const handleEditName = name => {
    setName(name);
  };

  const { data, isLoading, error } = useQuery(
    QUERY_KEYS.MY_ASSISTANTS,
    getMyAssistants,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const assistants = Array.isArray(data?.assistants) ? data.assistants : [];

  const renderSkeletons = [...Array(6)].map((_, index) => (
    <Skeleton
      key={`skeleton-${index}`}
      baseColor={"#e4e4e7"}
      highlightColor={"#F1F1F1"}
      duration={1}
      height={132}
      borderRadius={16}
    />
  ));

  const handleGenerateImage = async () => {
    setModalEditImage(true);
    setLoadingImage(true);
    getAssistantImagesDefalut()
      .then(images => {
        setGeneratedImages(images);
      })
      .finally(() => setLoadingImage(false));
  };

  const handleSaveData = () => {
    if (selectedAssistant) {
      const datas = JSON.parse(localStorage.getItem("assistantData")) || {};

      datas[selectedAssistant._id] = {
        avatar: avatar,
        name: name,
        role: role,
        timestamp: Date.now(),
      };

      localStorage.setItem("assistantData", JSON.stringify(datas));

      setSavedData(datas);

      dispatch(updateAssistantData({ avatar }));
      setModalEditImage(false);
    }
  };

  useEffect(() => {
    if (selectedAssistant) {
      queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
    }
  }, [selectedAssistant]);

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
    showRealoadNotification(error ? error.message : null, false);
  }, [error]);

  const getData = assistantId => {
    const assistantData =
      JSON.parse(localStorage.getItem("assistantData")) || {};
    const savedData = assistantData[assistantId];

    if (savedData) {
      const currentTime = Date.now();
      if (currentTime - savedData.timestamp > 2 * 60 * 60 * 1000) {
        delete assistantData[assistantId];
        localStorage.setItem("assistantData", JSON.stringify(assistantData));
        return null;
      }
      return savedData;
    }

    return null;
  };

  const newAssistant = JSON.parse(localStorage.getItem("newAssistant"));

  const assistantsToDisplay = newAssistant
    ? [newAssistant, ...assistants]
    : assistants;

  return (
    <>
      <div className="grid-horizontals-assistants">
        {isLoading
          ? renderSkeletons
          : assistantsToDisplay &&
            assistantsToDisplay.map((assistant, index) => {
              const savedData = getData(assistant._id);
              const avatar = savedData
                ? savedData.avatar
                : assistant.profileImage;
              const name = savedData ? savedData.name : assistant.name;
              const role = savedData ? savedData.role : assistant.role;

              return (
                <CardAssistantsNewLayout
                  setOpenModalInfo={() => handleCardClick(assistant)}
                  key={index}
                  direction="horizontal"
                  image={avatar || assistant.profileImage}
                  title={name || assistant?.name}
                  description={role || assistant?.role}
                  icon={assistant?.profileImage}
                  name={assistant?.userName}
                  myAssistant={true}
                  assistant={assistant}
                  edit={() => {
                    setOpenModal(true);
                    setSelectedAssistant(assistant);
                  }}
                  onSelect={() => {
                    dispatch(
                      setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                    );
                  }}
                />
              );
            })}
      </div>
      {assistantsToDisplay?.length < 1 && (
        <CardCreateAssistant onClick={onClick} />
      )}

      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => setOpenModalInfo(false)}
        assistant={selectedAssistant}
      />

      <ModalEditAssistant
        selectedAssistant={selectedAssistant}
        setOpenModal={setOpenModal}
        handleGenerateImage={handleGenerateImage}
        setModalEditImage={setModalEditImage}
        openModal={openModal}
        avatarSelected={avatar}
        handleSaveData={handleSaveData}
        handleEditRole={handleEditRole}
        handleEditName={handleEditName}
      />

      <ModalImagesAvatar
        showModal={modalEditImage}
        generatedImages={generatedImages}
        loadingImage={loadingImage}
        onClick={() => {}}
        onSelectGeneratedImage={handleSelectGeneratedImage}
        closeModal={() => setModalEditImage(false)}
        handleAvatarChange={handleSaveData}
      />
    </>
  );
};

export default ListMyAssistants;
