/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo, ReactNode, useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import coldarkCold from "react-syntax-highlighter/dist/esm/styles/prism/coldark-cold";
import remarkGfm from "remark-gfm";
import { showSuccessNotification } from "../../../../services/notifications";
import UseTranslation from "../../../../hooks/useTranslation";
import { MarkdownRendererI18n } from "./MarkdownRenderer.i18n";

const MarkdownComponents: object = {
  code({
    node,
    inline,
    className,
    children,
    ...props
  }: {
    node: { data: { meta: string } };
    inline: boolean;
    className: string;
  } & Record<string, unknown>): ReactNode {
    const [isCodeCopied, setIsCodeCopied] = useState(false);
    const { t } = UseTranslation(MarkdownRendererI18n);
    const match = /language-(\w+)/.exec(className || "");
    const code = String(children).replace(/\n$/, "");

    const handleCopyCode = useCallback(
      (code: string) => {
        navigator.clipboard.writeText(code);
        showSuccessNotification(t("notifications.copy-code"));
        setIsCodeCopied(true);
        setTimeout(() => {
          setIsCodeCopied(false);
        }, 2500);
      },
      [t]
    );

    if (inline) {
      return (
        <code className={className} style={{ color: "inherit" }} {...props}>
          {`\`${children}\``}
        </code>
      );
    }

    return match ? (
      <div className="bg-black overflow-hidden rounded-3">
        <div className="d-flex align-items-center justify-content-between px-3 my-2 text-white">
          <span className="mt-1">{match[1]}</span>
          <button
            className="btn btn-sm btn-outline-secondary align-content-center font-size-14 text-white-50"
            onClick={() => handleCopyCode(code)}
            disabled={isCodeCopied}
          >
            {isCodeCopied ? (
              <span className="d-flex align-items-center gap-1">
                {t("button.copied")} <i className="bx bx-check" />
              </span>
            ) : (
              <span className="d-flex align-items-center gap-1">
                {t("button.copy-code")} <i className="bx bx-copy" />
              </span>
            )}
          </button>
        </div>
        <SyntaxHighlighter
          style={coldarkCold}
          customStyle={{
            padding: "1rem",
            margin: 0,
            maxWidth: "100%",
            overflowX: "scroll",
          }}
          language={match[1]}
          PreTag="div"
          showLineNumbers={false}
          wrapLongLines={true}
          {...props}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    ) : (
      <code className={className} {...props} />
    );
  },
};

type Props = {
  content: string;
  className?: string;
};

const MarkdownRenderer: React.FC<Props> = ({ content, className }) => {
  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      components={MarkdownComponents}
    >
      {content}
    </ReactMarkdown>
  );
};

export default memo(MarkdownRenderer);
