/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import CardAttachment from "./CardAttachment";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import { getChannelJobStatus } from "../../../../api";
import ModalAttachFile from "../../../Modals/ModalAttachFile";
import { showRealoadNotification } from "../../../../services/notifications";

export function PreviewAttachmentFiles() {
  let showError = false;
  const configsMainChat = useGetConfigsMainChat();
  const [showModalAttachFile, setShowModalAttachFile] = useState(false);

  const {
    data: attachments,
    refetch,
    error
  } = useQuery(
    ["attachments", configsMainChat?.chatData?._id],
    async () => getChannelJobStatus(configsMainChat?.chatData?._id),
    {
      enabled: configsMainChat?.chatData?._id !== undefined,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (attachments && attachments?.some(attachment => !attachment.processed)) {
      const interval = setInterval(() => {
        refetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [attachments, refetch]);

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);

  return (
    <>
      {attachments && attachments.length > 0 ? (
        <>
          <div style={{ alignSelf: "flex-start" }}>
            <>
              <div
                className="d-flex mb-2 align-items-center gap-3 animate__animated animate__fadeInDown animated animate__faster"
              >
                <CardAttachment
                  attachment={attachments}
                  onClick={() => {
                    setShowModalAttachFile(true);
                  }}
                />
              </div>
            </>
          </div>
          <ModalAttachFile
            initialShowDocumentManage={showModalAttachFile}
            setShowModalAttachFile={setShowModalAttachFile}
            showModalAttachFile={showModalAttachFile}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}
