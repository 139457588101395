import Account from "./Account/Account";
import { setOpenLeftBarMobile } from "../../redux/general/action";
import { useDispatch, useSelector } from "react-redux";
import { IconMenu2 } from "@tabler/icons-react";
import { useGetConfigsMainChat } from "../../hooks/smallHooks";
import { useLocation } from "react-router-dom";
import TitleTopBar from "../PagesComponents/PageChat/Chats/ToolsTopBar/TitleTopBar";

const MenuTopBar = ({ publishAssistant = false, onClick = () => {} }) => {
  const dispatch = useDispatch();
  const isMobile = window.matchMedia("(max-width: 991.92px)").matches;
  const state = useSelector(state => state);
  const configsMainChat = useGetConfigsMainChat();
  const location = useLocation();

  const leftBar = state?.configsGeneralReducer?.openLeftBarMobile;

  return (
    <nav className="menu-top-bar">
      {!isMobile &&
        configsMainChat?.chatData?.channelName &&
        location.pathname !== "/assistants" &&
        location.pathname !== "/settings" &&
        location.pathname !== "/about" && (
          <TitleTopBar configsMainChat={configsMainChat} />
        )}
      {isMobile && (
        <button
          className="d-flex menu-btn"
          style={{ marginRight: 12 }}
          onClick={() => {
            dispatch(setOpenLeftBarMobile(!leftBar));
          }}
        >
          <span>Menu</span>
          <IconMenu2 stroke={1} size={14} />
        </button>
      )}
      <Account publishAssistant={publishAssistant} onClick={onClick} />
    </nav>
  );
};

export default MenuTopBar;
