import { useSelector } from "react-redux";
import COLORS from "../../utils/constants/colors";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const CardLandingPageType4 = ({ img, text, className }) => {
  const state = useSelector(state => state);

const breakpointLg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG; 

  return (
    <div
      className={`w-100 d-flex flex-column align-items-start ${className}`}
      style={{
        borderRadius: 16,
        gap: breakpointLg ? 16 : 40,
        maxWidth: breakpointLg ? "100%" : 328,
      }}
    >
      <div
      className="d-flex justify-content-center align-items-center"
        style={{
          background: COLORS.gray900,
          minWidth: 40,
          minHeight: 40,
          borderRadius: 12,
          cursor: "default",
          color: COLORS.white,
        }}
      >
        {img}
      </div>
      <p
        className="m-0 p-0 font-size-14 two-lines"
        style={{ color: COLORS.gray700 }}
      >
        {text}
      </p>
    </div>
  );
};

export default CardLandingPageType4;
