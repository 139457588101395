import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState } from "react";
import imgEmail from "../../../assets/images/icons-new-layout/EnvelopeOpenEmail.svg";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../services/notifications";
import ButtonType1 from "../../Buttons/ButtonType1";
import COLORS from "../../../utils/constants/colors";

export function RecoverPassword({ t, recoverEmail }) {
  const [email, setEmail] = useState(recoverEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [recoverPassword, setRecoverPassword] = useState(false);

  const supabaseClient = useSupabaseClient();

  async function handleRecoverPassword() {
    if (!email) {
      return;
    }

    try {
      setIsLoading(true);

      const { error } = await supabaseClient.auth.resetPasswordForEmail(
        email.trim(),
        {
          redirectTo: encodeURI(
            `${process.env.REACT_APP_PUBLIC_SITE_URL}/settings?tab=security&recoverPassword=true`
          ),
        }
      );

      if (error) {
        throw new Error(error?.message || "Failed to reset password");
      }
      setRecoverPassword(true);
      showSuccessNotification(t("sendEmailSuccessNotification"));
    } catch (error) {
      showErrorNotification(
        error?.message ||
          "Failed to reset password, please try again or contact support"
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      action=""
      onSubmit={e => {
        e.preventDefault();
      }}
      className="form-login-web2"
    >
      <div className="group">
        <label htmlFor="email">{t("email")}</label>
        <input
          type="text"
          name="email"
          id="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={t("placeholderEmail")}
          className="autofill-fix"
        />
      </div>

      <ButtonType1
        text={t("sendEmail")}
        isLoading={isLoading}
        variation="primary"
        props={{
          onClick: handleRecoverPassword,
          className: "btn-main-form",
          type: "button",
        }}
      />
      {recoverPassword && (
        <div
          className="d-flex flex-column justify-content-center align-items-center mt-3 gap-3 w-100"
          style={{
            borderRadius: 12,
            background: COLORS.gray100,
            padding: "32px 24px",
          }}
        >
          <img
            src={imgEmail}
            alt=""
            width={56}
            height={56}
            style={{ borderRadius: "50%" }}
          />
          <div className="d-flex flex-column align-items-center gap-3">
            <h5 className="m-0font-size-16">{t("check")}</h5>
            <p className="m-0 text-center font-size-14">
              {t("passwordIntroduction")}
            </p>
          </div>
        </div>
      )}
    </form>
  );
}
