import { IconArrowUp, IconPlayerStopFilled } from "@tabler/icons-react";

const SendButton = ({ isReceivingMessage, disableCancelMessage, onClick, emptyTextArea }) => (
  <button
    type="button"
    id="btn-send-message"
    className={`btn-actions button-primary`}
    disabled={disableCancelMessage || emptyTextArea}
    onClick={onClick}
  >
    {isReceivingMessage ? (
      <IconPlayerStopFilled size={22} />
    ) : (
      <IconArrowUp stroke={1.5} size={22} />
    )}
  </button>
);

export default SendButton;
