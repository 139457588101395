const initialState = {
  configsMainChat: JSON.parse(localStorage.getItem("configsMainChat")),
  listAssistants: null,
  listMachines: null,
  listTypeChats: null,
  openLeftBar: localStorage.getItem("openLeftBar")
    ? localStorage.getItem("openLeftBar") === "true"
    : true,
  windowWidth: window.innerWidth,
  openLogin: false,
  isLogged: JSON.parse(localStorage.getItem("sb.token"))?.access_token
    ? true
    : false,
  openLeftBarMobile: false,
};

const configsGeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONFIGS_MAIN_CHAT":
      localStorage.setItem("configsMainChat", JSON.stringify(action.payload));
      return {
        ...state,
        configsMainChat: JSON.parse(localStorage.getItem("configsMainChat")),
      };
    case "SET_LIST_ASSISTANTS":
      return {
        ...state,
        listAssistants: action.payload,
      };
    case "SET_WINDOW_WIDTH":
      return {
        ...state,
        windowWidth: action.payload,
      };
    case "SET_OPEN_LOGIN":
      return {
        ...state,
        openLogin: action.payload,
      };
    case "SET_OPEN_LEFT_BAR_MOBILE":
      return {
        ...state,
        openLeftBarMobile: action.payload,
      };
    case "SET_OPEN_LEFT_BAR":
      localStorage.setItem("openLeftBar", action.payload);
      return {
        ...state,
        openLeftBar: action.payload,
      };
    case "SET_IS_LOGGED":
      return {
        ...state,
        isLogged: action.payload,
      };
    case "SET_LIST_MACHINES":
      return {
        ...state,
        listMachines: action.payload,
      };
    case "SET_LIST_TYPE_CHATS":
      return {
        ...state,
        listTypeChats: action.payload,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        currentLanguage: action.payload,
      };
    default:
      return { ...state };
  }
};

export default configsGeneralReducer;
