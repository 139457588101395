/* eslint-disable react-hooks/exhaustive-deps */
import {
  IconArrowUp,
  IconBrandYoutube,
  IconChevronLeft,
  IconChevronRight,
  IconFileText,
  IconLoader2,
  IconWorld,
  IconX,
} from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";
import ButtonType1 from "../Buttons/ButtonType1";
import AttachDocumentMenu from "../Menus/AttachDocumentMenu";
import ModalGlobal from "./ModalGlobal";
import iconLink from "../../assets/images/icons-new-layout/LinkSimple.svg";
import iconFile from "../../assets/images/icons-new-layout/FileArrowUp.svg";
import Tooltip from "../Add-ons/Tooltip";
import { useEffect, useState } from "react";
import ComponentFileUploader from "../Add-ons/ComponentFileUploader";
import { useQuery } from "react-query";
import { deleteAttachment, getChannelJobStatus } from "../../api";
import { useGetConfigsMainChat } from "../../hooks/smallHooks";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../services/indexedDB/DocumentRepository";
import { queryClient } from "../../libs/ReactQuery";
import {
  showErrorNotification,
  showRealoadNotification,
  showSuccessNotification,
} from "../../services/notifications";
import { Switch } from "antd";
import { updateJobs } from "../../api/embeddings";
import UseTranslation from "../../hooks/useTranslation";
import { ModalAttachFileI18n } from "./ModalAttachFile.i18n";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import ButtonCloseModal from "../Buttons/ButtonCloseModal";

const ModalAttachFile = ({
  showModalAttachFile,
  setShowModalAttachFile,
  initialShowDocumentManage,
}) => {
  let showError = false;
  const [addLink, setAddLink] = useState(false);
  const configsMainChat = useGetConfigsMainChat();
  const [showDocumentManage, setShowDocumentManage] = useState(
    initialShowDocumentManage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showDivData, setShowDivData] = useState(false);
  const [buttonDeleteAttachment, setButtonDeleteAttachment] = useState(false);
  const [isUpdatingJobs, setIsUpdatingJobs] = useState(false);
  const [updatedJobs, setUpdatedJobs] = useState([]);
  const state = useSelector(state => state);

  const documentRepository = new DocumentRepository();

  const { t } = UseTranslation(ModalAttachFileI18n);

  const { data: attachments, error } = useQuery(
    ["attachments", configsMainChat?.chatData?._id],
    async () => getChannelJobStatus(configsMainChat?.chatData?._id),
    {
      enabled: configsMainChat?.chatData?._id !== undefined,
      staleTime: Infinity,
    }
  );

  const onToggle = () => {
    setAddLink(!addLink);
    if (attachments?.length < 1) {
      setTimeout(() => {
        setShowDivData(!showDivData);
      }, 400);
    } else {
      setShowDivData(!showDivData);
    }
  };

  const toggleDocumentList = () => {
    setShowDocumentManage(!showDocumentManage);
    setShowDivData(false);
  };

  async function handleDeleteAttachment(attachment) {
    setIsLoading(true);
    try {
      const repositoryDocumentId = makeDocumentId(
        attachment?.channel_id,
        attachment?.user_id,
        attachment?._id
      );

      await Promise.all([
        deleteAttachment(attachment?._id),
        documentRepository.deleteDocumentById(repositoryDocumentId),
      ]);

      await queryClient.invalidateQueries([
        "attachments",
        attachment?.channel_id,
      ]);
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateActiveJobs() {
    try {
      setIsUpdatingJobs(true);

      updatedJobs.forEach(job => {
        delete job.document;
      });

      await updateJobs(updatedJobs);

      await queryClient.invalidateQueries([
        "attachments",
        configsMainChat?.chatData?._id,
      ]);

      updatedJobs.forEach(async job => {
        await documentRepository.updateDocumentActiveStatus(
          makeDocumentId(job?.channel_id, job?.user_id, job?._id),
          job?.active
        );
      });

      setUpdatedJobs([]);
      setShowDocumentManage(false);

      showSuccessNotification("Jobs updated successfully!");
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsUpdatingJobs(false);
    }
  }

  const handleSwitchChange = (checked, document) => {
    setUpdatedJobs(prevUpdatedJobs => {
      const existingJobIndex = prevUpdatedJobs.findIndex(
        job => job._id === document._id
      );

      if (existingJobIndex !== -1) {
        const updatedArray = [...prevUpdatedJobs];
        updatedArray[existingJobIndex] = {
          ...updatedArray[existingJobIndex],
          active: checked,
        };
        return updatedArray;
      } else {
        return [
          ...prevUpdatedJobs,
          {
            ...document,
            active: checked,
          },
        ];
      }
    });
  };

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.SM;

  useEffect(() => {
    setShowDocumentManage(initialShowDocumentManage);
  }, [showModalAttachFile, initialShowDocumentManage]);

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);


  return (
    <ModalGlobal
      id="modal-attach-file"
      open={showModalAttachFile}
      classNameChildren={
        "p-3 justify-content-start modal-attachment animate__animated animate__fadeInDown animate__faster"
      }
      style={{ height: "calc(100% - 80px)", overflow: "hidden" }}
      onClick={() => {
        setShowModalAttachFile(false);
        setAddLink(false);
        setShowDocumentManage(false);
        setShowDivData(false);
      }}
    >
      <div className="w-100 d-flex justify-content-start">
        {!showDocumentManage ? (
          <div className="w-100 d-flex justify-content-between align-items-center mb-3">
            <h3>{t("title")}</h3>
            <ButtonCloseModal onClick={() => setShowModalAttachFile(false)} />
          </div>
        ) : (
          <div
            className="w-100 d-flex flex-column gap-2"
            style={{ marginBottom: 24 }}
          >
            <div className="d-flex align-items-center gap-2">
              <IconChevronLeft
                stroke={1.5}
                size={14}
                className="cursor-pointer"
                onClick={() => setShowDocumentManage(false)}
              />
              <h3>{t("dataManage.title")}</h3>
            </div>
            <span className="font-size-12">{t("dataManage.description")}</span>
          </div>
        )}
      </div>
      {!showDocumentManage && (
        <div className="w-100 d-flex flex-column gap-2 h-100">
          <div
            className="d-flex justify-content-center flex-column align-items-center gap-4 w-100 position-relative"
            style={{
              height: attachments?.length > 0 || addLink ? "40%" : "100%",
              transition: "height 0.5s ease-in-out",
              background: COLORS.gray100,
              borderRadius: 10,
            }}
          >
            <ComponentFileUploader />
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                background: COLORS.gray200,
              }}
            >
              <IconArrowUp
                style={{
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  background: COLORS.gray700,
                  color: COLORS.white,
                  padding: 5,
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3">
              <span className="m-0">{t("drag")}</span>
              <div className="d-flex align-items-center gap-3 justify-content-center">
                <ButtonType1
                  icon={iconFile}
                  props={{
                    className: "btn-file",
                    type: "button",
                  }}
                  text={t("buttons.select")}
                />
                <ButtonType1
                  icon={iconLink}
                  props={{
                    className: "btn-file",
                    type: "button",
                    onClick: onToggle,
                    style: { zIndex: 999 },
                  }}
                  text={t("buttons.addLink")}
                />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-start">
            <div
              className=" d-flex justify-content-between gap-1"
              style={{ color: COLORS.gray500, fontSize: 12 }}
            >
              <p>{t("supportedFiles.p1")}</p>
              <u>{t("supportedFiles.p2")},</u>
              <u>{t("supportedFiles.p3")}</u>
              <u>{t("supportedFiles.p4")}</u>
              <p>{t("supportedFiles.p5")}</p>
              <Tooltip
                text={t("supportedFiles.tooltip")}
                children={<u>{t("supportedFiles.p6")}</u>}
              />
            </div>
          </div>
          <>
            {attachments && attachments?.length > 0 && (
              <div className="w-100 mt-3 d-flex justify-content-between align-items-center animate__animated animate__fadeInUp animate__faster">
                <h4>{t("data")}</h4>{" "}
                <button
                  className="d-flex align-items-center gap-1 cursor-pointer"
                  onClick={toggleDocumentList}
                  type="button"
                >
                  <h4>
                    <u>{t("manage")}</u>
                  </h4>
                  <IconChevronRight stroke={1.5} size={14} />
                </button>
              </div>
            )}
          </>
          {showDivData && (
            <div className="w-100 animate__animated animate__fadeInUp animate__faster mb-2">
              <AttachDocumentMenu isOpen={true} onToggle={() => { }} />
            </div>
          )}

          <div className="w-100">
            <ul
              className="animate__animated animate__fadeInUp animate__faster overflow-auto"
              style={{ maxHeight: 250 }}
            >
              {attachments && attachments?.map((document, index) => (
                <li
                  className="d-flex flex-column align-items-start position-relative overflow-hidden"
                  key={document._id}
                  onMouseEnter={() => setButtonDeleteAttachment(index)}
                  onMouseLeave={() => setButtonDeleteAttachment(false)}
                  style={{
                    cursor: "pointer",
                    background: COLORS.gray100,
                    padding: "0 12px",
                  }}
                >
                  <div className="d-flex align-items-center gap-2 h-100">
                    {document.type === "document" ? (
                      <IconFileText stroke={1.5} size={16} />
                    ) : document.type === "website" ? (
                      <IconWorld stroke={1.5} size={16} />
                    ) : (
                      <IconBrandYoutube stroke={1.5} size={16} />
                    )}
                    <div className="d-flex flex-column justify-content-center" style={{ paddingLeft: 10 }}>
                      <label
                        className="one-line"
                        style={{ maxWidth: isMobile ? 150 : 300, fontSize: 12 }}
                      >
                        {document.filename}
                      </label>
                      <p style={{ fontSize: 10, margin: 0, textTransform: "capitalize" }}>{document.processed ? "Processed" : document.status}</p>
                    </div>
                    <span
                      className="text-uppercase px-2 fw-bold"
                      style={{
                        background: COLORS.white,
                        borderRadius: 5,
                        fontSize: 8,
                        letterSpacing: 1.7,
                      }}
                    >
                      {document.type}
                    </span>
                  </div>
                  {buttonDeleteAttachment === index && (
                    <button
                      className="d-flex align-items-center justify-content-center position-absolute font-size-12"
                      style={{
                        top: '30%',
                        right: 16,
                        padding: 0,
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        cursor: "pointer",
                        background: COLORS.white,
                      }}
                      type="button"
                      onClick={() => handleDeleteAttachment(document)}
                    >
                      {isLoading ? (
                        <IconLoader2 stroke={1.5} className="spin" />
                      ) : (
                        <IconX stroke={1.5} className="delete-attachment" />
                      )}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {showDocumentManage && (
        <div className="animate__animated animate__fadeInUp animate__faster w-100 d-flex flex-column gap-3">
          <span>{t("dataManage.status")}:</span>
          <ul>
            {attachments && attachments?.map((document, index) => {
              return (
                <li
                  key={document._id}
                  onMouseEnter={() => setButtonDeleteAttachment(index)}
                  onMouseLeave={() => setButtonDeleteAttachment(false)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="d-flex align-items-center gap-2 name-document"
                    style={{
                      padding: "0 12px",
                      background: COLORS.gray100,
                      height: "100%",
                      width: "100%",
                      borderRadius: 8,
                    }}
                  >
                    {document.type === "document" ? (
                      <IconFileText stroke={1.5} size={16} />
                    ) : document.type === "website" ? (
                      <IconWorld stroke={1.5} size={16} />
                    ) : (
                      <IconBrandYoutube stroke={1.5} size={16} />
                    )}
                    <Tooltip text={document.filename}>
                    <div className="d-flex flex-column justify-content-center" style={{ paddingLeft: 10 }}>
                    <label
                        className="one-line"
                        style={{ maxWidth: isMobile ? 130 : 280, fontSize: 12 }}
                      >
                        {document.filename}
                      </label>
                      <p style={{ fontSize: 10, margin: 0, textTransform: "capitalize" }}>{document.processed ? "Processed" : document.status}</p>
                    </div>
                    </Tooltip>
                    <span
                      className="text-uppercase px-2 fw-bold"
                      style={{
                        background: COLORS.white,
                        borderRadius: 5,
                        fontSize: 8,
                        letterSpacing: 1.7,
                      }}
                    >
                      {document.type}
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{
                      padding: "0 12px",
                      background: COLORS.gray100,
                      height: "100%",
                      borderRadius: 8,
                    }}
                  >
                    <Switch
                      size="small"
                      style={{
                        backgroundColor: COLORS.gray300,
                        accentColor: COLORS.primary,
                      }}
                      autoFocus={false}
                      disabled={!document.processed}
                      defaultValue={document.active}
                      onChange={checked =>
                        handleSwitchChange(checked, document)
                      }
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {showDocumentManage && (
        <div className="d-flex align-items-center gap-2 justify-content-end mt-auto w-100">
          <ButtonType1
            props={{
              type: "button",
              onClick: () => setShowDocumentManage(false),
              style: { padding: "10px 16px", borderRadius: 10 },
            }}
            text={"Cancel"}
          />
          <ButtonType1
            props={{
              type: "button",
              style: { padding: "10px 16px", borderRadius: 10 },
              onClick: updateActiveJobs,
              disabled: isUpdatingJobs || updatedJobs.length === 0,
            }}
            isLoading={isUpdatingJobs}
            text={"Save changes"}
          />
        </div>
      )}
    </ModalGlobal>
  );
};

export default ModalAttachFile;
