/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { setIsLogged, setOpenLogin } from "../../redux/general/action";
import { CSSTransition } from "react-transition-group";
import Web2Login from "./components/Web2Login";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { getAndUpdateUser, getUser } from "../../services/userHelper";
import { useEffect, useState } from "react";
import { showErrorNotification } from "../../services/notifications";
import { toast } from "sonner";
import { requestChallenge, verifyChallenge } from "../../api";
import { ethers } from "ethers";
import COLORS from "../../utils/constants/colors";
import { IconArrowLeft, IconChevronRightPipe } from "@tabler/icons-react";
import UseTranslation from "../../hooks/useTranslation";
import { GroupLoginI18n } from "./GroupLogin.i18n";

const STORAGE_KEYS = {
  USER_WALLET_SIGN: "signature",
  GOOGLE_LOGIN_REDIRECT: "google_login_redirect",
};

function GroupLogin({ isShow }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const supabaseClient = useSupabaseClient();

  const { t } = UseTranslation(GroupLoginI18n);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingWeb3, setIsLoadingWeb3] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResetingPassword, setIsResetingPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = getUser();
  const session = useSession();

  const googleRedirect = localStorage.getItem(
    STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT
  );

  function handleError(message, defaultMessage) {
    toast.error(message ?? defaultMessage, {
      style: {
        textTransform: "capitalize",
      },
    });
  }

  async function fetchAndSaveUserData() {
    try {
      setIsLoadingGoogle(true);
      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
      setIsLoadingGoogle(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSocialLogin() {
    try {
      setIsLoadingGoogle(true);

      localStorage.setItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT, "true");
      localStorage.setItem("web2Login", "true");

      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${process.env.REACT_APP_PUBLIC_SITE_URL}`,
        },
      });

      if (error) {
        if (!error?.message) {
          toast.error("An unexpected error occurred. Please try again.");
        } else {
          handleError(
            error?.message ??
              "Failed to login, please try again or contact support"
          );
        }
        return setIsLoadingGoogle(false);
      }
    } catch (error) {
      console.error(error || "Failed to login, please try again.");
      if (!error?.message) {
        toast.error("An unexpected error occurred. Please try again.");
      } else {
        handleError(error?.message ?? "Failed to login, please try again.");
      }
    } finally {
      setIsLoadingGoogle(false);
    }
  }

  async function handleLoginWithCredentials() {
    try {
      setIsLoading(true);

      localStorage.setItem("web2Login", "true");

      const { error } = await supabaseClient.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        if (!error?.message) {
          toast.error("An unexpected error occurred. Please try again.");
        } else {
          handleError(
            error?.message ??
              "Failed to login, please try again or contact support"
          );
        }
        return setIsLoading(false);
      }

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (error) {
      console.error(error || "Failed to login, please try again.");
      if (!error || !error?.message) {
        toast.error("Failed to login, please try again or contact support");
      } else {
        toast.error(error?.message ?? "Failed to login, please try again.");
      }
    }
  }

  async function handleRegister() {
    try {
      setIsLoading(true);

      const { error } = await supabaseClient.auth.signUp({
        email: email,
        password: password,
        options: {
          emailRedirectTo: process.env.REACT_APP_PUBLIC_SITE_URL,
        },
      });

      if (error) {
        if (!error.message) {
          toast.error("An unexpected error occurred. Please try again.");
        } else {
          handleError(
            error.message ??
              "Failed to login, please try again or contact support"
          );
        }
        return;
      }

      const emailDomain = email.split("@")[1].toLowerCase();

      const emailProviders = {
        "gmail.com": "https://mail.google.com",
        "outlook.com": "https://outlook.live.com",
        "hotmail.com": "https://outlook.live.com",
        "yahoo.com": "https://mail.yahoo.com",
      };

      const redirectToEmailProvider = () => {
        const url = emailProviders[emailDomain];
        if (url) {
          window.open(url, "_blank");
        }
      };

      toast(
        "Account created! Please confirm your email and check your spam folder if you don't see it.",
        {
          action: {
            label: "OK",
            onClick: () => {
              redirectToEmailProvider();
            },
          },
          duration: Infinity,
          className: "toast-no-creditis",
        }
      );
    } catch (err) {
      handleError(
        err.message ?? "Failed to login, please try again or contact support"
      );
      console.error(err || "Failed to login, please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLoginWithWeb3() {
    const signatureStorage = localStorage.getItem(
      STORAGE_KEYS.USER_WALLET_SIGN
    );

    if (signatureStorage) {
      return signatureStorage;
    }

    try {
      setIsLoadingWeb3(true);
      const provider = new ethers.BrowserProvider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const addressId = accounts[0];

      const challengeResponse = await requestChallenge(addressId);
      const challenge = challengeResponse;

      if (!challenge.success) {
        return showErrorNotification(
          challenge?.data ?? "Failed to login with Metamask"
        );
      }

      const ethereum = await window?.ethereum;
      const signer = await new ethers.BrowserProvider(ethereum).getSigner();

      const signature = await signer.signMessage(challenge.data);

      const verifyResponse = await verifyChallenge({
        message: challenge.data,
        signature: signature,
      });

      const authData = verifyResponse;

      await supabaseClient.auth.signInWithPassword({
        email: authData.user.email,
        password: addressId.toLowerCase(),
      });

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (error) {
      if (error?.code && error?.code === "ACTION_REJECTED") {
        return showErrorNotification(
          error?.shortMessage ??
            "Failed to login with Metamask, please try again or contact support"
        );
      }

      if (error?.code && error?.shortMessage) {
        return showErrorNotification(error?.shortMessage);
      }

      showErrorNotification(
        error?.message ||
          "Failed to login with Metamask, please try again or contact support"
      );
    } finally {
      setIsLoadingWeb3(false);
    }
  }

  useEffect(() => {
    if (!user && session && !googleRedirect && !isLoading) {
      fetchAndSaveUserData();
    }
  }, [session]);

  useEffect(() => {
    if (googleRedirect) {
      dispatch(setOpenLogin(true));
      localStorage.removeItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT);
    }
  }, []);

  return (
    <CSSTransition
      in={isShow}
      classNames="slide-in-right-300"
      timeout={300}
      unmountOnExit
    >
      <section className="group-main-login">
        {isResetingPassword && (
          <button
            className="d-flex align-items-center justify-content-start mb-3 gap-2"
            style={{ cursor: "unset" }}
          >
            <IconArrowLeft
              size={14}
              onClick={() => setIsResetingPassword(false)}
            />
            <span
              className=" text-decoration-underline cursor-pointer"
              onClick={() => setIsResetingPassword(false)}
            >
              {t("backToLogin")}
            </span>
          </button>
        )}
        <div className="d-flex justify-content-between">
          {isResetingPassword ? (
            <h3 className="m-0">{t("recoverPassword")}</h3>
          ) : (
            <h3 className="m-0">
              {isRegistering ? t("createAccount") : t("signIn")}
            </h3>
          )}
          <button
            onClick={() => {
              dispatch(setOpenLogin(false));
              setIsRegistering(false);
              setIsResetingPassword(false);
            }}
            className="align-self-end mb-2 btn-close-login"
            style={{
              height: 32,
              width: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "flex-end",
              borderRadius: 8,
              background: COLORS.gray100,
            }}
          >
            <IconChevronRightPipe
              style={{
                width: 16,
                height: 16,
                transition: "all .2s",
                transform: `rotate(${
                  state?.configsGeneralReducer?.openLeftBar ? 0 : 180
                }deg)`,
              }}
            />
          </button>
        </div>
        <div className="header ">
          {!isResetingPassword ? (
            <p>
              {isRegistering ? t("okAccount") : t("newAizzy")}
              <button onClick={() => setIsRegistering(!isRegistering)}>
                {isRegistering ? t("signIn") : t("getStarted")}
              </button>
            </p>
          ) : (
            <span style={{ color: COLORS.gray900 }} className="font-size-14">
              {t("recoverPasswordDescription")}
            </span>
          )}
        </div>
        <Web2Login
          isRegistering={isRegistering}
          isResetingPassword={isResetingPassword}
          setIsResetingPassword={setIsResetingPassword}
          handleLogin={
            isRegistering ? handleRegister : handleLoginWithCredentials
          }
          email={email}
          handleSocialLogin={handleSocialLogin}
          isLoading={isLoading}
          isLoadingGoogle={isLoadingGoogle}
          onChangeEmail={e => setEmail(e.target.value)}
          onChangePassword={e => setPassword(e.target.value)}
          handleLoginWeb3={handleLoginWithWeb3}
          isLoadingWeb3={isLoadingWeb3}
        />
        <p className="text-terms">{t("privacyPolicy")}</p>
      </section>
    </CSSTransition>
  );
}

export default GroupLogin;
