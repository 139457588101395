/* eslint-disable react-hooks/exhaustive-deps */
// components
import { useDispatch } from "react-redux";
import { Toaster } from "sonner";
import SideMenuType2 from "../../components/PagesComponents/PageChat/SideMenuType2/SideMenuType2";
import { useEffect } from "react";
import { setWindowWidth } from "../../redux/general/action";
import { useLocation } from "react-router-dom";

interface IndexProps {
  children: any;
}
const Index = (props: IndexProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setWindowWidth(window.innerWidth));
    window.addEventListener("resize", () => {
      dispatch(setWindowWidth(window.innerWidth));
    });
  }, []);

    const isLandingPage = location.pathname === "/about";

  return (
    <div className="d-flex">
    {!isLandingPage && <SideMenuType2 />}
    {props.children}
    {!isLandingPage && (
      <Toaster theme={"light"} position="top-center" duration={3000} />
    )}
  </div>
  );
};

export default Index;
