export const FaqsI18n = {
  "pt-BR": {
    questionOne: "O que é Aizzy?",
    responseOne:
      "Aizzy.ai é uma plataforma movida por IA que aprimora a produtividade, priorizando anonimato e privacidade. Ela oferece ferramentas para manuseio de documentos, pesquisa inteligente, criação de conteúdo e colaboração — tudo em um ambiente seguro e privado.",

    questionTwo: "Como começar?",
    responseTwo:
      "Basta conectar-se via MetaMask, explorar as ferramentas e começar a usar o Aizzy.ai anonimamente para suas necessidades de produtividade e colaboração.",

    questionThree: "O que é o token $AIZ e como é utilizado?",
    responseThree:
      "O token $AIZ é o token utilitário do Aizzy.ai. Ele é utilizado para acessar funcionalidades premium, pagar por serviços de IA e recompensar contribuintes dentro do ecossistema. O Aizzy.ai utiliza o token $AIZ como sua principal moeda. Com o $AIZ, você pode acessar todos os modelos de texto, imagem e código, tornando-o uma solução flexível e econômica para suas necessidades.",

    questionFour: "Como o Aizzy protege meu anonimato?",
    responseFour:
      "Aizzy.ai usa tecnologia blockchain e acesso anônimo via MetaMask, garantindo que nenhuma informação pessoal seja compartilhada ou rastreada enquanto você usa a plataforma.",

    questionFive: "Posso criar agentes de IA sem habilidades técnicas?",
    responseFive:
      "Sim! O Aizzy.ai permite que os usuários construam agentes de IA privados de forma simples, usando suas ferramentas intuitivas, mesmo sem conhecimento técnico prévio.",

    questionSix: "Que tipos de conteúdo o Aizzy.ai pode me ajudar a criar?",
    responseSix:
      "O Aizzy.ai ajuda a gerar cópias de marketing, postagens de blog e outros materiais criativos, mantendo sua propriedade intelectual privada e segura.",
  },

  "en-US": {
    questionOne: "What is Aizzy?",
    responseOne:
      "Aizzy.ai is an AI-powered platform that enhances productivity while prioritizing anonymity and privacy. It offers tools for document handling, smart search, content creation, and collaboration—all in a secure and private environment.",

    questionTwo: "How do I get started?",
    responseTwo:
      "Simply connect via MetaMask, explore the tools, and start using Aizzy.ai anonymously for your productivity and collaboration needs.",

    questionThree: "What is the $AIZ token, and how is it used?",
    responseThree:
      "The $AIZ token is Aizzy.ai’s utility token. It is used to access premium features, pay for AI services, and reward contributors within the ecosystem. Aizzy.ai uses the $AIZ token as its primary currency. With $AIZ, you can access all text, image, and code language models, making it a flexible and cost-effective solution for your needs.",

    questionFour: "How does Aizzy protect my anonymity?",
    responseFour:
      "Aizzy.ai uses blockchain technology and anonymous access through MetaMask, ensuring that no personal information is shared or tracked while you use the platform.",

    questionFive: "Can I create AI agents without technical skills?",
    responseFive:
      "Yes! Aizzy.ai enables users to build private AI agents effortlessly using its intuitive tools, even without prior technical knowledge.",

    questionSix: "What types of content can Aizzy.ai help me create?",
    responseSix:
      "Aizzy.ai assists in generating marketing copy, blog posts, and other creative materials while keeping your intellectual property private and secure.",
  },

  "es-ES": {
    questionOne: "¿Qué es Aizzy?",
    responseOne:
      "Aizzy.ai es una plataforma impulsada por IA que mejora la productividad mientras prioriza el anonimato y la privacidad. Ofrece herramientas para la gestión de documentos, búsqueda inteligente, creación de contenido y colaboración, todo en un entorno seguro y privado.",

    questionTwo: "¿Cómo empezar?",
    responseTwo:
      "Simplemente conéctate a través de MetaMask, explora las herramientas y comienza a usar Aizzy.ai de manera anónima para tus necesidades de productividad y colaboración.",

    questionThree: "¿Qué es el token $AIZ y cómo se utiliza?",
    responseThree:
      "El token $AIZ es el token de utilidad de Aizzy.ai. Se utiliza para acceder a funciones premium, pagar por servicios de IA y recompensar a los colaboradores dentro del ecosistema. Aizzy.ai utiliza el token $AIZ como su moneda principal. Con $AIZ, puedes acceder a todos los modelos de texto, imagen y código, lo que lo convierte en una solución flexible y rentable para tus necesidades.",

    questionFour: "¿Cómo Aizzy protege mi anonimato?",
    responseFour:
      "Aizzy.ai utiliza tecnología blockchain y acceso anónimo a través de MetaMask, asegurando que no se comparta ni se rastree ninguna información personal mientras usas la plataforma.",

    questionFive: "¿Puedo crear agentes de IA sin habilidades técnicas?",
    responseFive:
      "¡Sí! Aizzy.ai permite a los usuarios construir agentes de IA privados de manera fácil usando sus herramientas intuitivas, incluso sin conocimientos técnicos previos.",

    questionSix: "¿Qué tipos de contenido puede ayudarme a crear Aizzy.ai?",
    responseSix:
      "Aizzy.ai ayuda a generar copias de marketing, publicaciones de blogs y otros materiales creativos, manteniendo tu propiedad intelectual privada y segura.",
  },
};
