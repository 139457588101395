import {
  IconCode,
  IconMessageDots,
  IconSearch,
} from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import COLORS from "../../utils/constants/colors";
import { KEYS_MAIN_CHAT } from "../../services/chat/defaultConfigsMainChat";
import { useDispatch, useSelector } from "react-redux";
import { setConfigsMainChat } from "../../redux/general/action";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import UseTranslation from "../../hooks/useTranslation";
import { ModalSelectModelI18n } from "./ModalSelectModel.i18n";
import { ListGroupModel } from "./ListModelsModal/ListGroupModel";
import { ListModelFlat } from "./ListModelsModal/ListModelsOrder";
import DropDownSelectOrder from "../Add-ons/DropDownSelectOrder";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import ButtonCloseModal from "../Buttons/ButtonCloseModal";

const ModalSelectModel = ({
  openModal,
  setOpenModal,
  setSearchModel,
  searchModel,
  machinesToDisplay,
  allModels,
  allModelsChats,
  allModelsCode,
  allModelsCheaper,
  machinesToDisplayCode,
  machinesToDisplayCheaper,
  machinesToDisplayMoreExpensive,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");
  const [sortOrder, setSortOrder] = useState(null);
  const [activeModelAll, setActiveModelAll] = useState(null);
  const [activeModelChat, setActiveModelChat] = useState(null);
  const [activeModelCode, setActiveModelCode] = useState(null);
  const [activeModelCheaper, setActiveModelCheaper] = useState(null);
  const [activeModelMoreExpensive, setActiveModelMoreExpensive] =
    useState(null);
  const [selectedOrder, setSelectedOrder] = useState("");
  const state = useSelector(state => state);
  const { t } = UseTranslation(ModalSelectModelI18n);

  const tabs = [
    {
      id: "all",
      label: t("tabs.all"),
      quantity: allModels?.length,
    },
    {
      id: "chat",
      label: t("tabs.chat"),
      icon: <IconMessageDots stroke={1.5} style={{ width: 16, height: 16 }} />,
      quantity: allModelsChats?.length,
    },
    {
      id: "code",
      label: t("tabs.code"),
      icon: <IconCode stroke={1.5} style={{ width: 16, height: 16 }} />,
      quantity: allModelsCode?.length,
    },
  ];

  const handleSelect = () => {
    setSearchModel("");
    setOpenModal(false);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSearchModel("");
    setActiveTab(
      activeModelCheaper || activeModelMoreExpensive ? "sorted" : "all"
    );
  };

  const filterGroups = (groups, searchText) => {
    if (!searchText || !Array.isArray(groups)) return groups;

    const lowerSearchText = searchText.toLowerCase();

    return (
      groups &&
      groups
        .map(group => {
          const filteredMachines = Array.isArray(group.machines)
            ? group.machines.filter(machine =>
                machine.name.toLowerCase().includes(lowerSearchText)
              )
            : [];

          if (
            filteredMachines.length > 0 ||
            (group.owner && group.owner.toLowerCase().includes(lowerSearchText))
          ) {
            return {
              ...group,
              machines: filteredMachines,
            };
          }

          return null;
        })
        .filter(group => group !== null)
    );
  };

  const getGroupsForActiveTab = () => {
    switch (activeTab) {
      case "all":
        return machinesToDisplay;
      case "chat":
        return machinesToDisplay;
      case "code":
        return machinesToDisplayCode;
      case "cheaper":
        return machinesToDisplayCheaper;
      case "more":
        return machinesToDisplayMoreExpensive;
      default:
        return [];
    }
  };

  const groups = getGroupsForActiveTab();
  const filteredGroups = filterGroups(groups, searchModel);

  const activeModelSortOrder = id => {
    if (sortOrder === "cheaper") {
      setActiveModelCheaper(id);
      setActiveModelMoreExpensive(null);
      dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.machine, id));
      handleSelect();
    } else {
      setActiveModelMoreExpensive(id);
      setActiveModelCheaper(null);
      dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.machine, id));
      handleSelect();
    }
  };

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;

  return (
    <ModalGlobal
      open={openModal}
      classNameChildren={`p-sm-4 p-3 modal-children-select-model justify-content-start ${
        openModal
          ? "animate__animated animate__fadeInDown animate__faster"
          : "animate__animated animate__fadeOut"
      }`}
      onClick={closeModal}
    >
      <div
        className="d-flex flex-column gap-4 w-100"
        style={{ height: "100%" }}
      >
        <header className="d-flex justify-content-between">
          <h3 className="m-0">{t("title")}</h3>
         <ButtonCloseModal onClick={closeModal} />
        </header>
        <div className="d-flex flex-column gap-3">
          <div
            className="d-flex gap-2 px-3 rounded-4 align-items-center"
            style={{ background: "#F2F4F7" }}
          >
            <IconSearch stroke={1.5} size={14} />
            <input
              type="text"
              placeholder="Search for a model"
              style={{ height: 44, color: COLORS.gray500, padding: 0 }}
              onChange={e => {
                setSearchModel(e.target.value);
              }}
            />
          </div>
          {!searchModel && (
            <Swiper
              className="w-100 m-0 d-flex overflow-visible"
              spaceBetween={8}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                992: {
                  slidesPerView: 5,
                },
              }}
            >
              {tabs?.map(tab => (
                <SwiperSlide
                  type="button"
                  key={tab.id}
                  className={`tab-button w-auto d-flex align-items-center gap-2 ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  onClick={() => {
                    setActiveTab(tab.id);
                    setSortOrder(null);
                    if (activeModelMoreExpensive || activeModelCheaper) {
                      return;
                    } else {
                      setSelectedOrder("Model type");
                    }
                  }}
                >
                  {tab.icon}
                  <div className="d-flex gap-1">
                    <span className="font-size-12">{tab.label}</span>
                    {!!tab.quantity && (
                      <span className="font-size-11">{`(${tab.quantity})`}</span>
                    )}
                  </div>
                </SwiperSlide>
              ))}
              {!isMobile && !searchModel && (
                <DropDownSelectOrder
                  selectedOrder={selectedOrder ? selectedOrder : "Model type"}
                  setSelectedOrder={setSelectedOrder}
                  setActiveTab={setActiveTab}
                  onClick={order => {
                    setSortOrder(order);
                  }}
                />
              )}
            </Swiper>
          )}
          {isMobile && !searchModel && (
            <DropDownSelectOrder
              selectedOrder={selectedOrder ? selectedOrder : "Model type"}
              setSelectedOrder={setSelectedOrder}
              setActiveTab={setActiveTab}
              onClick={order => {
                setSortOrder(order);
              }}
            />
          )}
        </div>

        <div
          className="list-group-model"
          style={{ flexGrow: 1, overflowY: "auto" }}
        >
          {activeTab === "all" && (
            <ListGroupModel
              groups={filteredGroups}
              activeModel={activeModelAll}
              setActiveModel={id => {
                setActiveModelAll(id);
                dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.machine, id));
                handleSelect();
                setSelectedOrder("");
                setActiveModelCheaper(null);
                setActiveModelMoreExpensive(null);
              }}
              searchModel={searchModel}
            />
          )}
          {activeTab === "chat" && (
            <ListGroupModel
              groups={filteredGroups}
              activeModel={activeModelChat}
              setActiveModel={id => {
                setActiveModelChat(id);
                dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.machine, id));
                handleSelect();
                setSelectedOrder("");
              }}
              searchModel={searchModel}
            />
          )}
          {activeTab === "code" && (
            <ListGroupModel
              groups={filteredGroups}
              activeModel={activeModelCode}
              setActiveModel={id => {
                setActiveModelCode(id);
                dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.machine, id));
                handleSelect();
                setSelectedOrder("");
              }}
              searchModel={searchModel}
            />
          )}
          {activeTab === "sorted" && (
            <ListModelFlat
              searchModel={searchModel}
              setActiveModel={id => {
                setActiveModelAll(null);
                setActiveModelChat(null);
                setActiveModelCode(null);
                activeModelSortOrder(id);
              }}
              activeModel={
                sortOrder === "cheaper"
                  ? activeModelCheaper
                  : activeModelMoreExpensive
              }
              machines={
                sortOrder === "cheaper"
                  ? machinesToDisplayCheaper
                  : machinesToDisplayMoreExpensive
              }
            />
          )}
        </div>
      </div>
    </ModalGlobal>
  );
};

export default ModalSelectModel;
