import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

const DropDownSelectOrder = ({
  label,
  setSelectedOrder,
  selectedOrder,
  setActiveTab,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown-container">
      <div className="d-flex gap-2 align-items-center">
        <span className="m-0 font-size-12" style={{ whiteSpace: "nowrap" }}>
          {"Group by"}
        </span>
        <div className="dropdown-header font-size-12" onClick={toggleDropdown}>
          {selectedOrder}
          <IconChevronDown
            className="rotate"
            stroke={1.5}
            size={14}
            style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </div>
      </div>
      {isOpen && (
        <div className="dropdown-list animate__animated animate__fadeIn animate__faster">
          <div
            className="dropdown-item"
            onClick={() => {
              setSelectedOrder("Cost (cheaper)");
              setActiveTab("sorted");
              onClick("cheaper");
              setIsOpen(false);
            }}
          >
            Cost (cheaper)
          </div>
          <div
            className="dropdown-item"
            onClick={() => {
              setSelectedOrder("Cost (expensive)");
              setActiveTab("sorted");
              onClick("expensive");
              setIsOpen(false);
            }}
          >
            Cost (expensive)
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownSelectOrder;
