import ButtonCloseModal from "../Buttons/ButtonCloseModal";

const ModalGlobal = ({
  style,
  children,
  className,
  classNameChildren,
  onClick = () => {},
  open,
  id = "main-modal",
}) => {
  return (
    open && (
      <div
        id={id}
        className={`modal-custom ${className}`}
        onClick={() => {
          onClick();
        }}
      >
        <div
          className={`d-flex flex-column modal-content-global ${classNameChildren}`}
          onClick={e => e.stopPropagation()}
          style={{
            ...style,
            maxHeight: 840,
          }}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default ModalGlobal;
