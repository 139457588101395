import Claude from "../../assets/images/icons-new-layout/claude.svg";
import Cohere from "../../assets/images/icons-new-layout/cohere.svg";
import Gpt from "../../assets/images/icons-new-layout/gpt.svg";
import Grok from "../../assets/images/icons-new-layout/grok.svg";
import Gemini from "../../assets/images/icons-new-layout/gemini.svg";
import Mistral from "../../assets/images/icons-new-layout/mistral.svg";
import Meta from "../../assets/images/icons-new-layout/meta.svg";
import Other from "../../assets/images/icons-new-layout/other.svg";
import Palm from "../../assets/images/icons-new-layout/palm.svg";
import Qwen from "../../assets/images/icons-new-layout/qwen.png";
import Router from "../../assets/images/icons-new-layout/router.svg";
import Yi from "../../assets/images/icons-new-layout/yi.svg";

const imagesModels = [
  { name: "Claude", image: Claude },
  { name: "Cohere", image: Cohere },
  { name: "GPT", image: Gpt },
  { name: "Grok", image: Grok },
  { name: "Gemini", image: Gemini },
  { name: "Mistral", image: Mistral },
  { name: "Llama3", image: Meta },
  { name: "Llama2", image: Meta },
  { name: "Other", image: Other },
  { name: "PaLM", image: Palm },
  { name: "Qwen", image: Qwen },
  { name: "Router", image: Router },
  { name: "Yi", image: Yi },
];

  export default imagesModels;