export const ModalCreateAssistantsI18n = {
  "pt-BR": {
    buttons: {
      previous: "Anterior",
      cancel: "Cancelar",
      next: "Próximo",
      create: "Criar",
      edit: "Salvar",
    },
    notification: {
      cancel: "Você irá perder todos os dados preenchidos, deseja mesmo cancelar?",
      verify: "Por favor, preencha todos os campos para criar seu assistente",
    },
    title: "Publicar Assistentes",
    paragraph:
      "Desbloqueie o potencial do seu fluxo de trabalho com nossos assistentes intuitivos",
    tabs: {
      general: {
        title: "Informações gerais",
        inputs: {
          name: {
            label: "Nome",
            placeholder: "Digite o nome do seu assistente",
          },
          about: {
            label: "Sobre",
            placeholder: "Forneça um breve resumo",
            tooltip: "Em poucas palavras, forneça um resumo das tarefas e responsabilidades que seu assistente irá desempenhar."
          },
          role: {
            label: "Função",
            placeholder: "Selecionar função",
          },
          category: {
            label: "Categoria",
            placeholder: "Selecionar categoria",
          },
          active: {
            label: "Ativar assistente para uso?",
            placeholder: {
              yes: "Sim",
              no: "Não",
            },
          },
          control: {
            label: "Controle de visibilidade",
            placeholder: {
              public: "Público (Qualquer usuário poderá visualizá-lo)",
              private: "Privado (Somente você poderá visualizá-lo)",
            },
          },
          avatar: {
            label: "Avatar",
            placeholder: "Selecionar",
          },
        },
      },
      data: {
        title: "Dados e Conhecimento",
        inputs: {
          prompt: {
            label: "Prompt",
            placeholder: "Descreva as atividades do assistente",
            tooltip: "Prompt é uma instrução inicial que você dá a uma inteligência artificial para determinar como ela deve se comportar, quais tarefas deve realizar e quais são seus objetivos."
          },
          language: {
            label: "Idioma",
            placeholder: "Selecionar idioma",
          },
        },
      },
      review: {
        title: "Informações gerais",
        inputs: {
          name: {
            label: "Nome",
          },
          about: {
            label: "Sobre",
          },
          role: {
            label: "Função",
          },
          category: {
            label: "Categoria",
          },
          active: {
            label: "Assistente ativo",
          },
          control: {
            label: "Controle de visibilidade",
          },
          avatar: {
            label: "Avatar",
          },
          prompt: {
            label: "Prompt",
          },
          language: {
            label: "Idioma",
          },
        },
      },
    },
  },
  "en-US": {
    buttons: {
      previous: "Previous",
      cancel: "Cancel",
      next: "Next",
      create: "Create",
      edit: "Save",
    },
    notification: {
      cancel: "You will lose all data, do you want to cancel?",
      verify: "Please fill in all the fields to create your assistant",
    },
    title: "Publish Assistants",
    paragraph:
      "Unleash the potential of your workflow with our intuitive assistants",
    tabs: {
      general: {
        title: "General info",
        inputs: {
          name: {
            label: "Name",
            placeholder: "Enter your assistant name",
          },
          about: {
            label: "About",
            placeholder: "Provide a short about",
            tooltip: "Please provide a summary of the tasks and responsibilities your assistant will perform."
          },
          role: {
            label: "Role",
            placeholder: "Select role",
          },
          category: {
            label: "Category",
            placeholder: "Select category",
          },
          active: {
            label: "Activate assistant for use?",
            placeholder: {
              yes: "Yes",
              no: "No",
            },
          },
          control: {
            label: "Visibility control",
            placeholder: {
              public: "Public (Any user can view it)",
              private: "Private (Only you can view it)",
            },
          },
          avatar: {
            label: "Avatar",
            placeholder: "Select",
          },
        },
      },
      data: {
        title: "Data & Knowiedge",
        inputs: {
          prompt: {
            label: "Prompt",
            placeholder: "Describe the activities of the assistant",
            tooltip: "Prompt is an instruction that your AI assistant will use to determine how it should behave, what tasks it should perform and what are its goals."
          },
          language: {
            label: "Language",
            placeholder: "Select language",
          },
        },
      },
      review: {
        title: "General info",
        inputs: {
          name: {
            label: "Name",
          },
          about: {
            label: "About",
          },
          role: {
            label: "Role",
          },
          category: {
            label: "Category",
          },
          active: {
            label: "Active assistant",
          },
          control: {
            label: "Control visibility",
          },
          avatar: {
            label: "Avatar",
          },
          prompt: {
            label: "Prompt",
          },
          language: {
            label: "Language",
          },
        },
      },
    },
  },
  "es-ES": {
    buttons: {
      previous: "Anterior",
      cancel: "Cancelar",
      next: "Siguiente",
      create: "Crear",
      edit: "Guardar",
    },
    notification: {
      cancel: "Usted va a abandonar todos los datos, ¿desea cancelar?",
      verify: "Por favor, rellena todos los campos para crear tu asistente",
    },
    title: "Publicar Asistentes",
    paragraph:
      "Desbloquea el potencial de tu flujo de trabajo con nuestros asistentes intuitivos",
    tabs: {
      general: {
        title: "Información general",
        inputs: {
          name: {
            label: "Nombre",
            placeholder: "Ingrese el nombre de su asistente",
          },
          about: {
            label: "Sobre",
            placeholder: "Proporciona un breve resumen",
            tooltip: "Por favor proporciona una resumen general de las actividades y responsabilidades del asistente."
          },
          role: {
            label: "Rol",
            placeholder: "Seleccionar rol",
          },
          category: {
            label: "Categoría",
            placeholder: "Seleccionar categoría",
          },
          active: {
            label: "¿Activar asistente para uso?",
            placeholder: {
              yes: "Si",
              no: "No",
            },
          },
          control: {
            label: "Control de visibilidad",
            placeholder: {
              public: "Público (Cualquier usuario puede verlo)",
              private: "Privado (Solo tú puedes verlo)",
            },
          },
          avatar: {
            label: "Avatar",
            placeholder: "Seleccionar",
          },
        },
      },
      data: {
        title: "Datos y Conocimiento",
        inputs: {
          prompt: {
            label: "Prompt",
            placeholder: "Describe las actividades del asistente",
            tooltip: "Prompt es una instrucción que el asistente de IA utilizará para determinar qué debe comportar, que tareas debe realizar y que objetivos debe alcanzar."
          },
          language: {
            label: "Idioma",
            placeholder: "Seleccionar idioma",
          },
        },
      },
      review: {
        title: "Información general",
        inputs: {
          name: {
            label: "Nombre",
          },
          about: {
            label: "Sobre",
          },
          role: {
            label: "Rol",
          },
          category: {
            label: "Categoría",
          },
          active: {
            label: "Asistente activo",
          },
          control: {
            label: "Control de visibilidad",
          },
          avatar: {
            label: "Avatar",
          },
          prompt: {
            label: "Prompt",
          },
          language: {
            label: "Idioma",
          },
        },
      },
    },
  },
};
