export const GroupLoginI18n = {
  "pt-BR": {
    signIn: "Entrar",
    signUp: "Inscrever-se",
    recoverPassword: "Esqueceu a senha?",
    recoverPasswordDescription:
      "Sem problemas! Digite seu e-mail para redefinir a sua senha.",
    backToLogin: "Voltar para o login",
    getStarted: "Começar",
    createAccount: "Criar nova conta",
    forgotPassword: "Esqueceu a senha?",
    sendEmail: "Enviar e-mail",
    sendEmailSuccessNotification: "E-mail enviado com sucesso!",
    firstName: "Primeiro Nome",
    lastName: "Sobrenome",
    email: "E-mail",
    password: "Senha",
    login: "Login",
    newAizzy: "Novo no Aizzy?",
    okAccount: "Já tem uma conta?",
    google: "Entrar com Google",
    metaMask: "Entrar com MetaMask",
    placeholderEmail: "Seu endereço de e-mail",
    placeholderPassword: "Sua senha",
    placeholderFirstName: "Digite o primeiro nome",
    placeholderLastName: "Digite o sobrenome",
    wallet: "Conectar carteira",
    privacyPolicy:
      "Continuando, aceitas os Termos de Serviço de AizzyAI e a Política de privacidade.",
    check: "Verifique seu e-mail",
    passwordIntroduction: "Instruções para redefinir sua senha serão enviadas para seu e-mail caso você já tenha se registrado.",
  },
  "es-ES": {
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    recoverPassword: "¿Olvidaste tu contraseña?",
    recoverPasswordDescription:
      "¡No hay problema! Solo ingresa tu correo electrónico para restablecerlo.",
    backToLogin: "Volver al inicio de sesión",
    getStarted: "Empezar",
    createAccount: "Crear nova cuenta",
    forgotPassword: "¿Olvidaste la contraseña?",
    sendEmail: "Enviar correo electrónico",
    sendEmailSuccessNotification: "Correo enviado con éxito!",
    email: "Correo electrónico",
    password: "Contraseña",
    firstName: "Primer Nombre",
    lastName: "Apellido",
    login: "Iniciar sesión",
    newAizzy: "¿Nuevo en Aizzy?",
    okAccount: "¿Ya tienes una cuenta?",
    google: "Iniciar sesión con Google",
    metaMask: "Iniciar sesión con MetaMask",
    placeholderEmail: "Tu correo electrónico",
    placeholderPassword: "Tu contraseña",
    placeholderFirstName: "Ingrese el nombre de pila",
    placeholderLastName: "Ingrese el apellido",
    wallet: "Conectar billetera",
    privacyPolicy:
      "Continuando, aceptas los Términos de Servicio de AizzyAI y la Política de privacidad.",
    check: "Revise su correo",
    passwordIntroduction: "Las instrucciones para restablecer su contraseña han sido enviadas a su correo electrónico si ya se ha registrado.",
  },
  "en-US": {
    signIn: "Sign In",
    signUp: "Sign Up",
    recoverPassword: "Forgot your password?",
    recoverPasswordDescription:
      "No problem! Just enter the email address that you signed up with to reset it.",
    backToLogin: "Back to Login",
    getStarted: "Get Started",
    createAccount: "Create new account",
    forgotPassword: "Forgot your password?",
    sendEmail: "Send Email",
    sendEmailSuccessNotification: "Email sent successfully!",
    email: "Email",
    password: "Password",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    login: "Login",
    newAizzy: "New to Aizzy?",
    okAccount: "Already have an account?",
    google: "Sign in with Google",
    metaMask: "Sign in with MetaMask",
    placeholderEmail: "Your email address",
    placeholderPassword: "Your password",
    placeholderFirstName: "Enter first name",
    placeholderLastName: "Enter last name",
    wallet: "Connect wallet",
    privacyPolicy:
      "By continuing, you agree to AizzyAI’s Terms of Service and consent to its Privacy Policy.",
    check: "Check your mail",
    passwordIntroduction: "Password reset instructions sent to your email if it has already been registered.",
  },
};
