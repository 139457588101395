import { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../services/notifications";
import { renameChannel } from "../../api";
import { UseRedux } from "../../hooks/index";
import UseTranslation from "../../hooks/useTranslation";
import { ModalChannelRenameI18n } from "./ModalChannelRename.i18n";
import { QUERY_KEYS, queryClient } from "../../libs/ReactQuery";
import { changeSelectedChat, getChannelJobStatus } from "../../redux/actions";
import { setConfigsMainChat } from "../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../services/chat/defaultConfigsMainChat";

export function ModalChannelRename({ isOpen, onClose, channel }) {
  const {
    _id: channelId,
    assistantId,
    channelName: initialChannelName,
  } = channel?.chatData || {};
  const [isLoading, setIsLoading] = useState(false);
  const [channelName, setChannelName] = useState(initialChannelName);
  const { t } = UseTranslation(ModalChannelRenameI18n);
  const { dispatch } = UseRedux();

  useEffect(() => {
    if (isOpen) {
      setChannelName(initialChannelName);
    }
  }, [isOpen, initialChannelName]);

  const onSubmit = async () => {
    if (!channelName) {
      return;
    }

    try {
      setIsLoading(true);
      const params = { channelId, assistantId, channelName };
      await renameChannel(params);

      const channelWithNewTitle = { ...channel.chatData, channelName };
      dispatch(changeSelectedChat(channelWithNewTitle));
      dispatch(getChannelJobStatus(channelWithNewTitle?._id));
      updateCachedChannels(channelWithNewTitle, channel);
      dispatch(
        setConfigsMainChat(KEYS_MAIN_CHAT.chatData, channelWithNewTitle)
      );

      onClose();
      setChannelName("");
      showSuccessNotification(t("success.message"));
    } catch (err) {
      showErrorNotification(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCachedChannels = (channelWithNewTitle, channel) => {
    try {
      const queryKey = QUERY_KEYS.CHANNELS;
      const cachedData = queryClient.getQueryData(queryKey);

      if (cachedData) {
        const updatedData = cachedData.map(c =>
          c._id === channel.chatData._id
            ? { ...c, channelName: channelWithNewTitle.channelName }
            : c
        );

        queryClient.setQueryData(queryKey, updatedData);
      } else {
        console.warn(`No cached found.`);
      }
    } catch (error) {
      console.error("Error updating cached channels:", error);
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const handleToggle = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      tabIndex={-1}
      centered
      scrollable
      id="modal-rename-channel"
      role="dialog"
      backdrop="static"
    >
      <ModalHeader className="modal-title-custom" toggle={handleToggle}>
        {t("page.label")}
      </ModalHeader>
      <ModalBody onClick={e => e.stopPropagation()}>
        <Input
          type="text"
          className="form-control"
          id="addgroupname-input"
          placeholder={t("input.placeholder")}
          aria-label={t("input.title")}
          value={channelName}
          onChange={e => setChannelName(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="link" type="button" onClick={onClose}>
          {t("button.close")}
        </Button>
        <Button
          type="button"
          className="btn-primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <i className="bx bx-loader bx-spin align-middle"></i>
          ) : (
            t("button.rename")
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
