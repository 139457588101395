import { useSelector } from "react-redux";
import { IconCheck } from "@tabler/icons-react";
import UseTranslation from "../../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistants.i18n";
import InputCreateAssistant from "./InputCreateAssistant";
import TextAreaField from "./TextAreaField";

const ReviewPublishAssistants = () => {
  const { t } = UseTranslation(ModalCreateAssistantsI18n);
  const assistantData = useSelector(
    state => state.assistantReducer.assistantData
  );

  const getActiveStatus = enabled => (enabled ? "Sim" : "Não");

  const getControlStatus = isPrivate =>
    isPrivate
      ? "Privado (Somente você poderá visualizá-lo)"
      : "Público (Qualquer usuário poderá visualizá-lo)";

  return (
    <div className="review-publish-assistants" style={{ paddingRight: "10px" }}>
      <div className="create-assistants-general-info">
        <InputCreateAssistant
          label={t("tabs.review.inputs.name.label")}
          value={assistantData.name}
        />
        <InputCreateAssistant
          label={t("tabs.review.inputs.about.label")}
          value={assistantData.about}
        />
        <InputCreateAssistant
          label={t("tabs.review.inputs.role.label")}
          value={assistantData.role}
        />
        <InputCreateAssistant
          label={t("tabs.review.inputs.active.label")}
          value={getActiveStatus(assistantData.enabled)}
        />
        <InputCreateAssistant
          label={t("tabs.review.inputs.control.label")}
          value={getControlStatus(assistantData.private)}
        />
        <div className="d-flex w-100 justify-content-between align-items-start">
          <span className="span">{t("tabs.review.inputs.avatar.label")}</span>
          <div className="select-avatar w-100 d-flex flex-column justify-content-start gap-2">
            <div
              style={{ width: 110, height: 40 }}
              className="d-flex justify-content-center align-items-center div-input-upload-avatar"
            >
              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <img
                  src={assistantData.avatar}
                  alt=""
                  width={24}
                  height={24}
                  style={{ borderRadius: "50%" }}
                />
                <IconCheck size={12} stroke={3} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="create-assistants-data">
        <TextAreaField
          label={t("tabs.review.inputs.prompt.label")}
          value={assistantData.prompt}
        />
        <InputCreateAssistant
          label={t("tabs.review.inputs.language.label")}
          value={assistantData.language}
        />
      </div>
    </div>
  );
};

export default ReviewPublishAssistants;
