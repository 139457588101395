import UseUserCredits from "../../hooks/useUserCredits";
import UseTranslation from "../../hooks/useTranslation";
import UserSubscriptionI18n from "./UserSubscriptionI18n";
import Tooltip from "../Add-ons/Tooltip";

export function UserSubscription({
  large = false,
  showCreditsExplanation = false,
}) {
  const { subscription } = UseUserCredits();
  const { t } = UseTranslation(UserSubscriptionI18n);

  const formatSubscriptionPeriod = () => {
    const periodEnd = new Date(subscription.current_period_end);
    const periodStart = new Date(subscription.current_period_start);
    const daysDifference = (periodEnd - periodStart) / (1000 * 3600 * 24);

    return daysDifference > 30
      ? t("subscription.annual")
      : t("subscription.monthly");
  };

  return (
    <div className="d-flex flex-column">
      <Tooltip
        children={
          <strong
            className={`${large ? "fs-3" : "small"} m-0 p-0`}
            style={{ fontWeight: "600", lineHeight: "1.5" }}
          >
            {t("subscription.subscriber")} {formatSubscriptionPeriod()}
          </strong>
        }
        text={`${t("subscription.validUntil")} ${new Date(
          subscription.current_period_end
        ).toLocaleDateString()}`}
      />

      {showCreditsExplanation && (
        <div className="w-100 credits-explanation" style={{lineHeight: "1.7", fontSize: large ? "1rem" : "0.9rem" }}>
          <p
            className={`${large ? "fs-6" : "small"} p-0`}
            style={{ fontWeight: "400", lineHeight: "1.5" }}
          >
            {`${t("subscription.validUntil")} ${new Date(
              subscription.current_period_end
            ).toLocaleDateString()}`}
          </p>

          <p className="mt-4">{t("subscription.explanation")}</p>
          <h5 className="mt-3" style={{ fontWeight: "600" }}>
            {t("subscription.creditExplanationTitle")}
          </h5>
          <p>{t("subscription.creditExample1")}</p>
          <pre
            style={{
              backgroundColor: "#f8f9fa",
              padding: "10px",
              borderRadius: "5px",
              lineHeight: "1.6",
              fontSize: "0.9rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {t("subscription.creditExample1Details")}
          </pre>
          <p>{t("subscription.creditExample2")}</p>
          <pre
            style={{
              backgroundColor: "#f8f9fa",
              padding: "10px",
              borderRadius: "5px",
              lineHeight: "1.6",
              fontSize: "0.9rem",
            }}
          >
            {t("subscription.creditExample2Details")}
          </pre>
          <p style={{ fontWeight: "600" }}>
            {t("subscription.creditDeducted")}
          </p>
          <p>{t("subscription.totalDebit")}</p>
        </div>
      )}
    </div>
  );
}
