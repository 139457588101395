import { IconCode, IconMessage, IconWorld } from "@tabler/icons-react";

const KEYS_MAIN_CHAT = {
  machine: "machine",
  chatData: "chatData",
  assistant: "assistant",
  typeChat: "typeChat",
  enableWebSearch: "enableWebSearch",
};


const LIST_TYPE_CHAT = [
  {
    title: "chatting",
    icon: <IconMessage stroke={1.5} />,
    description: "chatting",
    prefix: "",
  },
  {
    title: "coding",
    icon: <IconCode stroke={1.5} />,
    description: "coding",
    prefix: "",
  },
  {
    title: "Web Searching",
    icon: <IconWorld stroke={1.5} />,
    description:"webSearching",
    prefix: "",
  },
];

const defaultConfigsMainChat = (props = {}) => {
  return {
    [KEYS_MAIN_CHAT.machine]: props.machine,
    [KEYS_MAIN_CHAT.chatData]: props.chatData,
    [KEYS_MAIN_CHAT.assistant]: props.assistant,
    [KEYS_MAIN_CHAT.typeChat]: props.typeChat,
    [KEYS_MAIN_CHAT.enableWebSearch]: props.enableWebSearch,
  };
};

export default defaultConfigsMainChat;
export { KEYS_MAIN_CHAT, LIST_TYPE_CHAT };
