import COLORS from "../../utils/constants/colors";

const ButtonFooterMessage = ({ icon, style }) => {
  return (
    <button
      style={{
        backgroundColor: COLORS.white,
        borderStyle: "none",
        outlineStyle: "none",
        color: "inherit",
        width: 32,
        height: 32,
        marginLeft: "auto",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        ...style,
      }}
    >
      {icon}
    </button>
  );
};

export default ButtonFooterMessage;
