import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { IconLink, IconRobotFace, IconTrendingUp } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import CardLandingPageType4 from "../../Cards/CardLandingPageType4";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import UseTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";

const SectionForLandingPage = () => {
  const state = useSelector(state => state);
  const { t } = UseTranslation(LandingPageI18n);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  useLayoutEffect(() => {
    const scroller = document.querySelector(".main-landing-page") || window;

    gsap.registerPlugin(ScrollTrigger);

    requestAnimationFrame(() => {
      ScrollTrigger.defaults({
        scroller,
      });

      gsap.fromTo(
        ".title-landing-page",
        { y: 200, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ".section-for",
            start: "top 70%",
            end: breakpointLg ? "bottom 100%" : "bottom 95%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );

      gsap.fromTo(
        ".card1-section-for",
        { x: -500 },
        {
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".section-for",
            start: "top 50%",
            end: breakpointLg ? "bottom 100%" : "bottom 80%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );

      gsap.fromTo(
        ".card2-section-for",
        { y: !breakpointLg && 300, opacity: breakpointLg && 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: ".section-for",
            start: "top 50%",
            end: breakpointLg ? "bottom 100%" : "bottom 80%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );

      gsap.fromTo(
        ".card3-section-for",
        { x: 500 },
        {
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".section-for",
            start: "top 50%",
            end: breakpointLg ? "bottom 100%" : "bottom 80%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section
      className="d-flex flex-column justify-content-center align-items-center section-for"
      style={{
        gap: breakpointLg ? 64 : 120,
        background: `linear-gradient(180deg, ${COLORS.white} 30%, ${COLORS.gray100} 100%)`,
      }}
    >
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{
          maxWidth: 1440,
          padding: breakpointLg ? "80px 16px" : "180px",
          gap: 120,
        }}
      >
        <div className="title-landing-page">
          <TitleLandingPage
            darkMode
            mediumFont
            gap={24}
            alignItems={"center"}
            textAlign={"center"}
            headerText={t("sectionFour.badge")}
            headerWidth={100}
            widthTitle={500}
            border={`1px solid ${COLORS.gray200}`}
            borderRadius={100}
            title={t("sectionFour.title")}
            description={t("sectionFour.description")}
            showButton={false}
          />
        </div>
        <div
          className="w-100 d-flex flex-column flex-lg-row justify-content-between"
          style={{ gap: 40 }}
        >
          <CardLandingPageType4
            img={<IconRobotFace stroke={1.5} size={25} />}
            text={t("sectionFour.cardOne")}
            className="card1-section-for" // Classe para animação do card
          />
          <CardLandingPageType4
            img={<IconLink stroke={1.5} size={25} />}
            text={t("sectionFour.cardTwo")}
            className="card2-section-for" // Classe para animação do card
          />
          <CardLandingPageType4
            img={<IconTrendingUp stroke={1.5} size={25} />}
            text={t("sectionFour.cardThree")}
            className="card3-section-for" // Classe para animação do card
          />
        </div>
      </div>
    </section>
  );
};

export default SectionForLandingPage;
