import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/Default/index";
import { AuthProtected } from "./AuthProtected";
import { publicRoutes } from "./allRoutes";

const Index = () => {
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <DefaultLayout>{route.component}</DefaultLayout>
            </AuthProtected>
          }
          key={idx}
        />
      ))}
      {/* {privateRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <DefaultLayout>{route.component}</DefaultLayout>
            </AuthProtected>
          }
          key={idx}
        />
      ))} */}
      <Route path="/account" element={<Navigate replace to="/profile" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Index;
