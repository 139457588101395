import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeTypeChat from "./ChangeTypeChat";
import ChangeAssistant from "./ChangeAssistant";
import ChangeMachine from "./ChangeMachine";
import {
  IconSettings,
} from "@tabler/icons-react";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
  useGetIsLogged,
} from "../../../../hooks/smallHooks";
import UseSendMessage from "../../../../hooks/useSendMessage";
import { setOpenLogin } from "../../../../redux/general/action";
import AttachButton from "./ButtonActions/AttachButton";
import SendButton from "./ButtonActions/SendButton";
import ModalGlobal from "../../../Modals/ModalGlobal";
import COLORS from "../../../../utils/constants/colors";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import ModalAttachFile from "../../../Modals/ModalAttachFile";
import UseTranslation from "../../../../hooks/useTranslation";
import { PageChatI18n } from "../../../Add-ons/PageChat.i18n";
import UseUserCredits from "../../../../hooks/useUserCredits";
import ModalNoCredits from "../../../Modals/ModalNoCredits";
import ButtonCloseModal from "../../../Buttons/ButtonCloseModal";

const InputMainChat = () => {
  const dispatch = useDispatch();

  const [question, setQuestion] = useState("");
  const [openModalNoCredits, setOpenModalNoCredits] = useState(false);

  const [isProcessingAudio] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emptyTextArea, setEmptyTextArea] = useState(false);

  const [showModalAttachFile, setShowModalAttachFile] = useState(false);

  const { t } = UseTranslation(PageChatI18n);

  const textareaRef = useRef(null);

  const isLogged = useGetIsLogged();
  const state = useSelector(state => state);
  const configsMainChat = useGetConfigsMainChat();

  const { handleCreateChannel: createChannel } = useCreateNewChannel();
  const {
    sendMessage,
    isReceivingMessage,
    disableCancelMessage,
    cancelMessage,
  } = UseSendMessage();

  const { hasSufficientCredits, hasSubscriptionActive } =
    UseUserCredits();

  const isSubscriptionActive = hasSubscriptionActive();
  const isFreeModel = configsMainChat?.machine?.includes(":free");

  // const handleSubmitAudio = async transcription => {
  //   if (!isFreeModel && !hasSufficientCredits()) {
  //     return;
  //   }

  //   if (isReceivingMessage) {
  //     cancelMessage();
  //   } else if (configsMainChat?.chatData) {
  //     sendMessage(transcription, true);
  //   } else {
  //     setQuestion(transcription);
  //     await createChannel();
  //     setTimeout(() => {
  //       const sendButton = document.querySelector("#btn-send-message");
  //       if (sendButton) {
  //         sendButton.click();
  //       }
  //     }, 300);
  //   }
  // };

  const handleSendButtonClick = async e => {
    if (!isLogged) {
      dispatch(setOpenLogin(true));
      return;
    }
    e.preventDefault();

    if (!isFreeModel && !hasSufficientCredits() && !isSubscriptionActive) {
      setOpenModalNoCredits(true);
      return;
    }

    if (isReceivingMessage) {
      cancelMessage();
    } else if (configsMainChat?.chatData) {
      sendMessage(question);
      setQuestion("");
    } else {
      await createChannel();
      setTimeout(() => {
        const sendButton = document.querySelector("#btn-send-message");
        if (sendButton) {
          sendButton.click();
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  }, [question]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  };

  const handleChange = e => {
    setQuestion(e.target.value);
    adjustHeight();
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendButtonClick(e);
    }
  };

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.MD;

  const toggleModal = () => {
    setIsOpenModal(true);
  };

  useEffect(() => {
    setEmptyTextArea(question === "");
  }, [question]);

  return (
    <>
      {!isMobile && (
        <form action="" className="d-flex flex-column p-3" style={{ gap: 12 }}>
          <textarea
            ref={textareaRef}
            autoFocus={true}
            name="input-main-chat"
            id="input-main-chat"
            placeholder="Digite aqui..."
            autoComplete="off"
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div className="group-buttons">
            <div className="selectors">
              <ChangeAssistant
                background={"#fff"}
                state={state}
                dispatch={dispatch}
              />
              <ChangeMachine background={"#fff"} state={state} />

              <ChangeTypeChat />
            </div>
            <div className="action-buttons">
              <AttachButton
                onClick={() => {
                  isLogged
                    ? setShowModalAttachFile(true)
                    : dispatch(setOpenLogin(true));
                }}
              />
              {/* <AudioRecorderButton
                onStopRecorder={handleSubmitAudio}
                onAudioProcessing={setIsProcessingAudio}
              /> */}
              {!isProcessingAudio && (
                <SendButton
                  isReceivingMessage={isReceivingMessage}
                  disableCancelMessage={disableCancelMessage}
                  onClick={handleSendButtonClick}
                  emptyTextArea={disableCancelMessage || (emptyTextArea && !isReceivingMessage)}
                  />
              )}
            </div>
            <ModalAttachFile
              setShowModalAttachFile={setShowModalAttachFile}
              showModalAttachFile={showModalAttachFile}
            />
          </div>
        </form>
      )}
      <>
        {isMobile && (
          <form
            action=""
            className="d-flex flex-column mb-1 p-2"
            style={{ gap: 6 }}
          >
            <div className="d-flex input-main-chat-mobile">
              <textarea
                ref={textareaRef}
                autoFocus={true}
                name="input-main-chat"
                id="input-main-chat"
                placeholder="Digite aqui..."
                autoComplete="off"
                value={question}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <div className="action-buttons">
                {!isProcessingAudio && (
                  <SendButton
                    isReceivingMessage={isReceivingMessage}
                    disableCancelMessage={disableCancelMessage}
                    onClick={handleSendButtonClick}
                  />
                )}
              </div>
            </div>
            <div className="group-buttons">
              <div className="selectors w-100 justify-content-between">
                <div className="media-send-actions-mobile d-flex align-items-center gap-2">
                  <ChangeTypeChat />
                  <div className="d-flex align-items-center gap-2">
                    <AttachButton
                      onClick={() => setShowModalAttachFile(true)}
                    />
                    {/* <AudioRecorderButton
                      onStopRecorder={handleSubmitAudio}
                      onAudioProcessing={setIsProcessingAudio}
                    /> */}
                  </div>
                </div>
                <div>
                  <div
                    className="change-settings-mobile d-flex px-2 align-items-center justify-content-center"
                    onClick={toggleModal}
                    style={{
                      background: "#fff",
                      height: 40,
                      borderRadius: 8,
                      width: 40,
                    }}
                  >
                    <IconSettings size={20} />
                  </div>
                  <ModalGlobal
                    open={isOpenModal}
                    classNameChildren={
                      "p-4 animate__animated animate__fadeInDown animate__faster"
                    }
                    style={{ height: "calc(100% - 60px)" }}
                  >
                    <div className="w-100 h-100 d-flex flex-column gap-3">
                      <div className="d-flex justify-content-end mb-2">
                        <ButtonCloseModal
                          onClick={() => setIsOpenModal(false)}
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <span>{t("assistants")}</span>
                        <div>
                          <ChangeAssistant
                            background={COLORS.gray100}
                            state={state}
                          />
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="d-flex flex-column gap-2">
                        <span>{t("models")}</span>
                        <ChangeMachine
                          background={COLORS.gray100}
                          state={state}
                        />
                      </div>
                    </div>
                  </ModalGlobal>
                </div>
              </div>
              <ModalAttachFile
                setShowModalAttachFile={setShowModalAttachFile}
                showModalAttachFile={showModalAttachFile}
              />
            </div>
          </form>
        )}
        <ModalNoCredits
          openModal={openModalNoCredits}
          setOpenModal={setOpenModalNoCredits}
        />
      </>
    </>
  );
};

export default InputMainChat;
