import PageAssistants from "../pages/Assistants";
import Settings from "../pages/Settings";
import Chat from "../pages/Chat";
import NotFoundPage from "../components/Add-ons/NotFoundPage";
import LandingPage from "../pages/LandingPage/Index";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/", component: <Chat /> },
  { path: "/assistants", component: <PageAssistants /> },
  { path: "/settings", component: <Settings /> },
  { path: "/about", component: <LandingPage /> },
];

const privateRoutes: Array<RouteProps> = [
  // { path: "/images", component: <ImagesPage /> },
  // { path: "/new-layout", component: <Newlayout /> },
  // { path: "/assistant/create", component: <CreateAssistantPage /> },
  // { path: "/community-assistants", component: <CommunityAssistantsPage /> },

  // { path: "/dashboard", component: <Dashboard /> },
  // { path: "/dashboard?assistantId=:assistantId", component: <Dashboard /> },
  { path: "*", component: <NotFoundPage /> },
];

export { privateRoutes, publicRoutes };
