/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigsMainChat,
  setListMachines,
  setListTypeChats,
  setWebModeLogin,
} from "../../redux/general/action";
import defaultConfigsMainChat, {
  KEYS_MAIN_CHAT,
  LIST_TYPE_CHAT,
} from "../../services/chat/defaultConfigsMainChat";
import { useGetConfigsMainChat, useGetIsLogged } from "../../hooks/smallHooks";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import ChatMain from "../../components/PagesComponents/PageChat/Chats/ChatMain/ChatMain";
import InputMainChat from "../../components/PagesComponents/PageChat/InputMainChat/InputMainChat";
import GroupLogin from "../../components/Login/GroupLogin";
import { PreviewAttachmentFiles } from "../../components/PagesComponents/PageChat/ListAttachmentFiles/PreviewAttachmentFiles";
import { getChannelJobStatus, getModels } from "../../api";
import { PageChatI18n } from "../../components/Add-ons/PageChat.i18n";
import UseTranslation from "../../hooks/useTranslation";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../services/indexedDB/DocumentRepository";
import { useQuery } from "react-query";
import { CompressedAttachmentDTO } from "../../@DTO/embeddings";
import { showErrorNotification } from "../../services/notifications";
import COLORS from "../../utils/constants/colors";
import { ChatWebSocketProvider } from "../../contexts/ChatWebscoketContext";
import UseUserCredits from "../../hooks/useUserCredits";

const defaultMachine = {
  id: "meta-llama/llama-3.1-8b-instruct",
  name: "Meta: Llama 3.1 8B Instruct",
  description:
    "Meta's latest class of model (Llama 3.1) launched with a variety of sizes & flavors. This 8B instruct-tuned version is fast and efficient.\n\nIt has demonstrated strong performance compared to leading closed-source models in human evaluations.\n\nTo read more about the model release, [click here](https://ai.meta.com/blog/meta-llama-3-1/). Usage of this model is subject to [Meta's Acceptable Use Policy](https://llama.meta.com/llama3/use-policy/).",
  architecture: {
    tokenizer: "Llama3",
    instruct_type: "llama3",
    modality: "text->text",
  },
};

const defaultAssistant = {
  _id: "646d98a9440f2a9bf026d4b2",
  name: "Bumble Chip",
  role: "Super Chat",
  about:
    "Meet Bumble Chip, your adorable and witty AI General Assistant! With a touch of whimsy and a hint of mischief, Bumble Chip is here to make your life easier and brighter. As a tech-savvy companion, Bumble Chip is always ready to lend a helping paw in various aspects of your daily routine. Whether you need assistance with tasks, information, or just a good laugh, Bumble Chip's friendly and playful nature will brighten your day. So sit back, relax, and let Bumble Chip sprinkle a little bit of magic and cheer into your world!",
  profileImage: "https://images.aizzy.ai/assets/profile/general_assistant.png",
  likes: 5,
};

const Chat = () => {
  const dispatch = useDispatch();
  const configsMainChat = useGetConfigsMainChat();
  const {
    openLeftBar,
    openLogin,
    listMachines,
    listTypeChats,
    // @ts-ignore
  } = useSelector(state => state.configsGeneralReducer);
  const isLogged = useGetIsLogged();
  const { credits } = UseUserCredits();

  const { t } = UseTranslation(PageChatI18n);

  const documentRepository = new DocumentRepository();

  const { data: attachments } = useQuery<CompressedAttachmentDTO[]>(
    ["attachments", configsMainChat?.chatData?._id],
    async () => getChannelJobStatus(configsMainChat?.chatData?._id),
    {
      enabled: configsMainChat?.chatData?._id !== undefined,
      staleTime: Infinity,
      refetchInterval: data => {
        const hasDocumentProcessing =
          Array.isArray(data) && data.some(job => !job.processed);

        return hasDocumentProcessing ? 8000 : false;
      },
      onSuccess: async attachments => {
        attachments &&
          attachments?.forEach(async attachment => {
            if (attachment?.processed && attachment?.document) {
              const documentId = makeDocumentId(
                attachment?.channel_id,
                attachment?.user_id,
                attachment?._id
              );

              await documentRepository.saveDocument(
                documentId,
                attachment?.document
              );
            }
          });
      },
      onError: (error: any) => {
        console.error("Error on get attachments", error);
        showErrorNotification(error?.message || "Error on get attachments");
      },
    }
  );

  const loadModels = useCallback(async () => {
    const cacheKey = "modelsCache";
    const cacheDuration = 24 * 60 * 60 * 1000;

    const cachedData = localStorage.getItem(cacheKey);
    const now = new Date().getTime();

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const cacheTime = parsedData.timestamp;

      if (now - cacheTime < cacheDuration) {
        dispatch(setListMachines(parsedData.models));
        return;
      }
    }

    if (isLogged) {
      try {
        const res = await getModels();
        const modelsData = res.data;

        localStorage.setItem(
          cacheKey,
          JSON.stringify({ models: modelsData, timestamp: now })
        );

        dispatch(setListMachines(modelsData));
      } catch (error) {
        console.error("Erro ao buscar modelos:", error);
      }
    } else {
      dispatch(setListMachines([defaultMachine]));
    }
  }, [dispatch, isLogged]);

  // Função para configurar o chat principal
  const initializeConfigsMainChat = useCallback(() => {
    if (!localStorage.getItem("configsMainChat") && listMachines) {
      const initialConfig = defaultConfigsMainChat({
        [KEYS_MAIN_CHAT.assistant]: defaultAssistant,
        [KEYS_MAIN_CHAT.machine]: defaultMachine?.id,
        [KEYS_MAIN_CHAT.chatData]: null,
        [KEYS_MAIN_CHAT.typeChat]: listTypeChats[0],
        [KEYS_MAIN_CHAT.enableWebSearch]: false,
      });

      localStorage.setItem("configsMainChat", JSON.stringify(initialConfig));
      dispatch(setConfigsMainChat(localStorage.getItem("configsMainChat")));
    }
  }, [dispatch, listMachines, listTypeChats]);

  // Função para configurar o modo de login da Web
  const initializeWebModeLogin = useCallback(() => {
    const webModeLogin = localStorage.getItem("webModeLogin") || "Web 2";
    dispatch(setWebModeLogin(webModeLogin));
  }, [dispatch]);

  // useEffect para carregar dados iniciais e configurar o estado do chat
  useEffect(() => {
    loadModels();
    dispatch(setListTypeChats(LIST_TYPE_CHAT));
    initializeWebModeLogin();
  }, [dispatch, loadModels, initializeWebModeLogin]);

  // useEffect para configurar as configurações do chat após os dados serem carregados
  useEffect(() => {
    initializeConfigsMainChat();
  }, [initializeConfigsMainChat]);

  return (
    <section className={`main-layout ${!openLeftBar ? "maximize" : ""}`}>
      {!configsMainChat?.chatData && <h1>{t("anonymously")}</h1>}
      <MenuTopBar />
      <ChatWebSocketProvider>
        <ChatMain />
        <div className="input-main-chat">
          <PreviewAttachmentFiles />
          <div
            className="gap-2 d-flex flex-column opened-chat"
            style={{ background: COLORS.gray100, borderRadius: 16 }}
          >
            <InputMainChat />
          </div>
        </div>
      </ChatWebSocketProvider>

      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default Chat;
