import twitter from "../../assets/images/icons-new-layout/twiterlogo.svg";
import instagram from "../../assets/images/icons-new-layout/instagranlogo.svg";
import youtube from "../../assets/images/icons-new-layout/youtubeLogo.svg";
import discord from "../../assets/images/icons-new-layout/discordfollow.svg";

const SocialMideas = [
  {
    name: "twitter",
    link: "https://x.com/aizzyai",
    image: twitter,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/aizzy.ai",
    image: instagram,
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/@AizzyAi",
    image: youtube,
  },
  {
    name: "discord",
    link: "https://discord.com/invite/YCwY4GtdWv",
    image: discord,
  },
];

export default SocialMideas;
