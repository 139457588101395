import { QUERY_KEYS } from "../libs/ReactQuery";
import { useGetIsLogged } from "./smallHooks";
import { useQuery } from "react-query";
import { getUserCredits, getUserSubscription } from "../api/creditPayments";

const UseUserCredits = () => {

  const isLogged = useGetIsLogged();

  const { data: credits, isLoading: isLoadingCredits } = useQuery(
    QUERY_KEYS.USER_CREDITS,
    getUserCredits,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
    }
  );

  const { data: subscription, isLoading: isLoadingSubscription } = useQuery(
    QUERY_KEYS.USER_SUBSCRIPTION,
    getUserSubscription,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const hasSufficientCredits = () => {
    const isActiveSubscription =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    if (isActiveSubscription) {
      return;
    }

    if (!credits?.amount || credits.amount <= 0) {
      return false;
    }

    if (credits.amount < 1) {
      return false;
    }

    return true;
  };

  const hasSubscriptionActive = () => {
    const isActive =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    return isActive;
  };

  return {
    isLoadingCredits,
    credits,
    subscription,
    isLoadingSubscription,
    hasSufficientCredits,
    hasSubscriptionActive,
  };
};

export default UseUserCredits;
