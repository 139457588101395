export function AizzyLogo({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1114_1564)">
        <g clipPath="url(#clip0_1114_1564)">
          <rect width="23.9104" height="23.9104" rx="6.53801" fill="#101828" />
          <g opacity="0.33" filter="url(#filter1_bf_1114_1564)">
            <rect
              x="1.49414"
              y="1.49414"
              width="20.9216"
              height="20.9216"
              rx="10.4608"
              fill="#101828"
              fillOpacity="0.24"
            />
          </g>
          <g opacity="0.11">
            <mask id="path-4-inside-1_1114_1564" fill="white">
              <path d="M20.1273 11.5807C20.1273 12.7006 20.1273 14.8679 20.1273 16.6894C20.1273 18.382 18.7561 19.7532 17.0635 19.7532C15.2864 19.7532 13.1665 19.7532 11.9547 19.7532C7.44119 19.7532 3.78223 16.0943 3.78223 11.5807C3.78223 7.06716 7.44119 3.4082 11.9547 3.4082C16.4683 3.4082 20.1273 7.06716 20.1273 11.5807Z" />
            </mask>
            <g filter="url(#filter2_i_1114_1564)">
              <path
                d="M20.1273 11.5807C20.1273 12.7006 20.1273 14.8679 20.1273 16.6894C20.1273 18.382 18.7561 19.7532 17.0635 19.7532C15.2864 19.7532 13.1665 19.7532 11.9547 19.7532C7.44119 19.7532 3.78223 16.0943 3.78223 11.5807C3.78223 7.06716 7.44119 3.4082 11.9547 3.4082C16.4683 3.4082 20.1273 7.06716 20.1273 11.5807Z"
                fill="url(#paint0_radial_1114_1564)"
                fillOpacity="0.24"
              />
            </g>
          </g>
          <g opacity="0.33" filter="url(#filter3_bf_1114_1564)">
            <rect
              x="1.49414"
              y="1.49414"
              width="20.9216"
              height="20.9216"
              rx="10.4608"
              fill="#101828"
              fillOpacity="0.24"
            />
          </g>
          <g opacity="0.33" filter="url(#filter4_b_1114_1564)">
            <rect
              x="1.49414"
              y="1.49414"
              width="20.9216"
              height="20.9216"
              rx="10.4608"
              fill="#101828"
              fillOpacity="0.24"
            />
          </g>
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="5.99359"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="9.185"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.33"
            cx="12.3774"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.33"
            cx="15.5688"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.33"
            cx="18.7602"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="0.786559"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="5.99359"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="9.185"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.55"
            cx="12.3774"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.55"
            cx="15.5688"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.55"
            cx="18.7602"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="3.97797"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.32"
            cx="5.99359"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="9.185"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="12.3774"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="15.5688"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="18.7602"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.88"
            cx="21.9516"
            cy="7.17035"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="5.99359"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="9.185"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="12.3774"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="15.5688"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="10.3618"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="5.99359"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="9.185"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="12.3774"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="15.5688"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.33"
            cx="18.7602"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="13.5532"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.66"
            cx="5.99359"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.55"
            cx="9.185"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="12.3774"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.33"
            cx="15.5688"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="18.7602"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="16.7446"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="5.99359"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.66"
            cx="9.185"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="12.3774"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="15.5688"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="18.7602"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="21.9516"
            cy="19.936"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="2.80218"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="5.99359"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="9.185"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="12.3774"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="15.5688"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.04"
            cx="18.7602"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <circle
            opacity="0.11"
            cx="21.9516"
            cy="23.1274"
            r="0.194762"
            fill="#D9D9D9"
          />
          <g filter="url(#filter5_i_1114_1564)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8968 4.76367C15.7333 4.76367 18.8435 7.8738 18.8435 11.7103V16.879C18.8435 17.8609 18.0475 18.6569 17.0655 18.6569H11.8968C8.06031 18.6569 4.9502 15.5468 4.9502 11.7103C4.9502 7.8738 8.06031 4.76367 11.8968 4.76367Z"
              fill="url(#paint2_radial_1114_1564)"
            />
          </g>
          <path
            d="M11.8968 4.78702C15.7205 4.78702 18.8201 7.88669 18.8201 11.7103V16.879C18.8201 17.848 18.0346 18.6336 17.0655 18.6336H11.8968C8.0732 18.6336 4.97355 15.5339 4.97355 11.7103C4.97355 7.88669 8.0732 4.78702 11.8968 4.78702Z"
            stroke="url(#paint3_linear_1114_1564)"
            strokeWidth="0.0467001"
          />
          <path
            opacity="0.11"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.933 10.8184C18.9666 11.0955 18.9838 11.3775 18.9838 11.6636V16.8323C18.9838 17.8142 18.1878 18.6102 17.2059 18.6102H12.0372C9.57139 18.6102 7.40564 17.3254 6.17285 15.3888C6.3552 11.5017 9.5644 8.40625 13.4966 8.40625C15.6527 8.40625 17.5914 9.33692 18.933 10.8184Z"
            fill="black"
          />
          <g opacity="0.22" filter="url(#filter6_f_1114_1564)">
            <ellipse
              cx="9.89722"
              cy="7.10577"
              rx="3.08804"
              ry="4.08781"
              transform="rotate(56.952 9.89722 7.10577)"
              fill="white"
            />
          </g>
          <g opacity="0.22" filter="url(#filter7_f_1114_1564)">
            <ellipse
              cx="9.95793"
              cy="6.78089"
              rx="1.72741"
              ry="2.28666"
              transform="rotate(56.952 9.95793 6.78089)"
              fill="white"
            />
          </g>
          <g filter="url(#filter8_dddi_1114_1564)">
            <circle cx="12.0024" cy="11.6977" r="3.01216" fill="#101828" />
          </g>
        </g>
        <rect
          x="0.02335"
          y="0.02335"
          width="23.8637"
          height="23.8637"
          rx="6.51466"
          stroke="url(#paint4_linear_1114_1564)"
          strokeOpacity="0.24"
          strokeWidth="0.0467001"
        />
      </g>
      <path
        d="M31.9375 19.425H28.8135L34.5014 3.26855H38.1145L43.8103 19.425H40.6863L36.371 6.5819H36.2448L31.9375 19.425ZM32.04 13.0902H40.5601V15.4411H32.04V13.0902Z"
        fill="#101828"
      />
      <path
        d="M45.1293 19.425V7.30767H47.9851V19.425H45.1293Z"
        fill="#101828"
      />
      <path
        d="M49.5709 19.425V17.6106L55.8189 9.7769V9.67435H49.776V7.30767H59.3137V9.25623L53.3654 16.9558V17.0584H59.5188V19.425H49.5709Z"
        fill="#101828"
      />
      <path
        d="M60.8256 19.425V17.6106L67.0736 9.7769V9.67435H61.0307V7.30767H70.5684V9.25623L64.6202 16.9558V17.0584H70.7735V19.425H60.8256Z"
        fill="#101828"
      />
      <path
        d="M74.7368 23.9995C74.3476 23.9995 73.9874 23.968 73.6561 23.9049C73.33 23.847 72.5022 23.4841 72.3076 23.4052L73.5377 21.4751C73.9532 21.5961 74.324 21.6539 74.6501 21.6487C74.9761 21.6434 75.2628 21.5408 75.5099 21.341C75.7624 21.1464 75.9754 20.8203 76.1489 20.3628L76.3935 19.708L71.9994 7.33818H75.0287L77.8214 16.4893H77.9476L80.7482 7.33818H83.7854L78.9337 20.9229C78.7076 21.5645 78.4078 22.1141 78.0344 22.5717C77.661 23.0345 77.2034 23.3868 76.6617 23.6288C76.1253 23.876 75.4837 23.9995 74.7368 23.9995Z"
        fill="#101828"
      />
      <defs>
        <filter
          id="filter0_i_1114_1564"
          x="0"
          y="0"
          width="23.9102"
          height="24.3538"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.443651" />
          <feGaussianBlur stdDeviation="0.658471" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1114_1564"
          />
        </filter>
        <filter
          id="filter1_bf_1114_1564"
          x="-7.84588"
          y="-7.84588"
          width="39.6019"
          height="39.6019"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.67001" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1114_1564"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1114_1564"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.1675"
            result="effect2_foregroundBlur_1114_1564"
          />
        </filter>
        <filter
          id="filter2_i_1114_1564"
          x="3.78223"
          y="3.4082"
          width="16.3447"
          height="16.4469"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.102156" />
          <feGaussianBlur stdDeviation="0.204313" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1114_1564"
          />
        </filter>
        <filter
          id="filter3_bf_1114_1564"
          x="-7.84588"
          y="-7.84588"
          width="39.6019"
          height="39.6019"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.67001" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1114_1564"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1114_1564"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.1675"
            result="effect2_foregroundBlur_1114_1564"
          />
        </filter>
        <filter
          id="filter4_b_1114_1564"
          x="-7.84588"
          y="-7.84588"
          width="39.6019"
          height="39.6019"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.67001" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1114_1564"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1114_1564"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_i_1114_1564"
          x="4.9502"
          y="4.76367"
          width="13.8936"
          height="13.987"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.0934002" />
          <feGaussianBlur stdDeviation="0.467001" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1114_1564"
          />
        </filter>
        <filter
          id="filter6_f_1114_1564"
          x="3.36719"
          y="0.978513"
          width="13.0596"
          height="12.2539"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.35547"
            result="effect1_foregroundBlur_1114_1564"
          />
        </filter>
        <filter
          id="filter7_f_1114_1564"
          x="5.11035"
          y="2.15918"
          width="9.69434"
          height="9.24317"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.35547"
            result="effect1_foregroundBlur_1114_1564"
          />
        </filter>
        <filter
          id="filter8_dddi_1114_1564"
          x="7.81901"
          y="8.01385"
          width="10.0561"
          height="10.0561"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.168921" dy="0.268827" />
          <feGaussianBlur stdDeviation="0.163786" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.065 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1114_1564"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.427581" dy="0.680467" />
          <feGaussianBlur stdDeviation="0.439388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08775 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1114_1564"
            result="effect2_dropShadow_1114_1564"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.844605" dy="1.34413" />
          <feGaussianBlur stdDeviation="1.00792" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1114_1564"
            result="effect3_dropShadow_1114_1564"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1114_1564"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.0251979"
            operator="erode"
            in="SourceAlpha"
            result="effect4_innerShadow_1114_1564"
          />
          <feOffset dy="0.176385" />
          <feGaussianBlur stdDeviation="0.279697" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.304525 0 0 0 0 0.349208 0 0 0 0 0.438574 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect4_innerShadow_1114_1564"
          />
        </filter>
        <radialGradient
          id="paint0_radial_1114_1564"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.78319 5.31123) rotate(73.8339) scale(17.0666)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.24" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1114_1564"
          x1="9.43294"
          y1="3.9686"
          x2="11.9547"
          y2="20.9105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1114_1564"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.46843 7.06365) rotate(50.8354) scale(10.5557 17.2577)"
        >
          <stop stopColor="#58E7D6" />
          <stop offset="1" stopColor="#2DB2A3" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1114_1564"
          x1="9.46843"
          y1="5.67432"
          x2="11.8968"
          y2="18.6569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.12" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1114_1564"
          x1="11.9552"
          y1="0"
          x2="11.9552"
          y2="23.9104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.16" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_1114_1564">
          <rect width="23.9104" height="23.9104" rx="6.53801" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
