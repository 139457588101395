import { useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const CardLandingPageType3 = ({
  imgIcon,
  maxWidth,
  backgroundChildren,
  backgroundFather,
  text,
  borderRadius,
  borderRadiusText,
  style: customStyles,
  rotate,
  fontSize,
}) => {
  const state = useSelector(state => state);

  const lg = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  return (
    <div
      className="d-flex justify-content-center align-items-start w-100 gap-2"
      style={{
        maxWidth: maxWidth,
        borderRadius: borderRadius,
        padding: lg ? 10 :16,
        background: backgroundFather,
        ...customStyles,
        transform: `${rotate} ${customStyles?.transform || ""}`
      }}
    >
      {imgIcon && (
        <div>
          <img
            src={imgIcon}
            alt=""
            style={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              marginTop: 10,
            }}
          />
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: backgroundChildren,
          borderRadius: borderRadiusText,
          fontSize: fontSize,
          padding: lg ? 10 : 16,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default CardLandingPageType3;
