import {
  IconCoins,
  IconHeadset,
  IconShield,
  IconTrendingUp,
  IconUserCircle,
} from "@tabler/icons-react";
import AffiliationSettings from "../../components/PagesComponents/SettingsNewLayout/AffiliationSettings";
import GeneralSettings from "../../components/PagesComponents/SettingsNewLayout/GeneralSettings";
import PlanSettings from "../../components/PagesComponents/SettingsNewLayout/PlanSettings";
import SecuritySettings from "../../components/PagesComponents/SettingsNewLayout/SecuritySettings";
import SupportSettings from "../../components/PagesComponents/SettingsNewLayout/SupportSettings";

const settings = [
  {
    id: "general",
    icon: <IconUserCircle style={{ width: 16, height: 16 }} />,
    label: "menu.general",
    content: <GeneralSettings />,
  },
  {
    id: "security",
    icon: <IconShield style={{ width: 16, height: 16 }} />,
    label: "menu.security",
    content: <SecuritySettings />,
  },
  {
    id: "credits",
    icon: <IconCoins style={{ width: 16, height: 16 }} />,
    label: "menu.credits",
    content: <PlanSettings />,
  },
  {
    id: "affiliation",
    icon: <IconTrendingUp style={{ width: 16, height: 16 }} />,
    label: "menu.affiliation",
    content: <AffiliationSettings />,
  },
  {
    id: "support",
    icon: <IconHeadset style={{ width: 16, height: 16 }} />,
    label: "menu.support",
    content: <SupportSettings />,
  },
];

export default settings;
